import React, { useState, useEffect } from 'react';
import { Canvas, useThree, useLoader } from '@react-three/fiber';
import { useGLTF, PerspectiveCamera, Sphere } from '@react-three/drei';
import { TextureLoader, BackSide, Vector3 } from 'three';
//import skyTexture from './styles/sky.png';

function Environment() {
  const { scene } = useGLTF('/free_low_poly_forest.glb');
  return <primitive object={scene} position={[-4, 0.73, 10]} scale={1} />;
}

function Moon({ position }) {
  return (
    <Sphere position={position} args={[5, 32, 32]} scale={10} castShadow receiveShadow>
      <meshStandardMaterial color="#d9d9d9" emissive="#D6DCE3" emissiveIntensity={0.80} />
    </Sphere>
  );
}

function SkySphere() {
//  const texture = useLoader(TextureLoader, skyTexture);
  return (
    <mesh scale={[50000, 50000, 50000]} position={[0, 0, 0]}>
      <sphereGeometry args={[500, 60, 40]} />
      <meshBasicMaterial /*map={texture}*/ side={BackSide} />
    </mesh>
  );
}

function CameraController() {
  const { camera } = useThree();
  useEffect(() => {
    camera.position.set(20, 0, 244);
    camera.rotation.set(70 * Math.PI / 1050, 0, 0);
  }, [camera]);

  return null;
}

function LandingPageForest() {
  const [moonPosition, setMoonPosition] = useState(new Vector3(-4, 50.73, -290));
  const radius = 484; // Radius of the arc
  const center = new Vector3(-4, 175.73, -290); // Center position of the arc movement

  useEffect(() => {
    const interval = setInterval(() => {
      setMoonPosition(prevPos => {
        const theta = (Date.now() / 3900) % (2 * Math.PI); // Calculates the angle
        return new Vector3(
          center.x + radius * Math.sin(theta), // Calculates the new X
          center.y,                            // Keeps Y the same
          center.z + radius * Math.cos(theta)  // Calculates the new Z
        );
      });
    }, 10);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Canvas shadows={true}>
        <CameraController />
        <ambientLight intensity={0.5} color="#345758" />
        <Moon position={moonPosition} />
        <directionalLight
          position={moonPosition}
          intensity={0.2}
          castShadow
          shadow-mapSize-width={2054}
          shadow-mapSize-height={2054}
        />
        <SkySphere />
        <Environment />
      </Canvas>
    </>
  );
}

export default LandingPageForest;

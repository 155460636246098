import React, { useState, useEffect } from 'react';
import { sendEmailVerification } from 'firebase/auth';
import { auth } from '../firebase-config';
import { useNavigate } from 'react-router-dom';

function EmailVerificationSend() {
  const [secondsRemaining, setSecondsRemaining] = useState(60);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Countdown timer for 60 seconds
    const countdown = setInterval(() => {
      setSecondsRemaining((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  const handleResendVerification = () => {
    sendEmailVerification(auth.currentUser)
      .then(() => {
        setEmailSent(true);
        setSecondsRemaining(60); // Reset the timer
      })
      .catch((err) => {
        console.error('Error sending email verification:', err);
        setError('Failed to send verification email. Please try again.');
      });
  };

  const containerStyle = {
    backgroundColor: '#f7d962',
    padding: '40px',
    borderRadius: '8px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    maxWidth: '500px',
    margin: '0 auto',
  };

  const headingStyle = {
    color: '#ff4740',
    fontSize: '2.5rem',
    fontWeight: 'bold',
  };

  const textStyle = {
    color: '#333',
    fontSize: '1.1rem',
    marginBottom: '20px',
  };

  const errorStyle = {
    color: '#ff4740',
    marginTop: '10px',
  };

  const gradientBackground = {
    background: 'linear-gradient(135deg, #ff4740 0%, #ff5a3f 50%, #ff7a59 100%)',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div style={gradientBackground}>
      <div style={containerStyle}>
        <h2 style={headingStyle}>Verification Email Sent</h2>
        <p style={textStyle}>
          Please check your inbox for the verification email. You must verify your email before logging in.
        </p>
        {emailSent && <p style={textStyle}>A new verification email has been sent!</p>}
        <p style={textStyle}>
          If you didn’t receive the email, you can resend it in <strong>{secondsRemaining} seconds</strong>.
        </p>

        {error && <p style={errorStyle}>{error}</p>}

        <button
          onClick={handleResendVerification}
          disabled={secondsRemaining > 0}
          className="btn btn-primary"
          style={{ backgroundColor: '#ff4740', borderColor: '#ff4740', marginRight: '10px' }}
        >
          Resend Email
        </button>

        <button
          onClick={() => navigate('/signin')}
          className="btn btn-secondary"
          style={{ backgroundColor: '#ff4740', borderColor: '#f7d962' }}
        >
          Back to Sign In
        </button>
      </div>
    </div>
  );
}

export default EmailVerificationSend;

import React, { useState, useEffect } from 'react';
import { auth, db } from './firebase-config';
import { updateProfile } from 'firebase/auth';
import "./styles/settings.css";

function Settings() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        setEmail(user.email);
        setUsername(user.displayName);
      }
    };

    fetchUserData();
  }, []);

  const handleUsernameChange = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (user && newUsername.trim() !== '') {
      // Update the profile display name in Firebase Auth
      await updateProfile(user, { displayName: newUsername })
        .then(() => {
          setUsername(newUsername);
          setNewUsername('');
          setMessage('Username updated successfully!');
        })
        .catch((error) => {
          setMessage('Failed to update username: ' + error.message);
        });
    } else {
      setMessage('Please enter a valid username.');
    }
  };

  return (
    <div className="settings-container">
      <h2>Settings</h2>
      <div className="settings-item">
        <label>Email:</label>
        <span>{email}</span>
      </div>
      <div className="settings-item">
        <label>Username:</label>
        <span>{username}</span>
      </div>
      <form onSubmit={handleUsernameChange} className="settings-form">
        <div className="settings-item">
          <label htmlFor="newUsername">New Username:</label>
          <input
            type="text"
            id="newUsername"
            value={newUsername}
            onChange={(e) => setNewUsername(e.target.value)}
            className="settings-input"
          />
        </div>
        <button type="submit" className="settings-button">Update Username</button>
      </form>
      {message && <div className="settings-message">{message}</div>}
    </div>
  );
}

export default Settings;

import React, { useEffect, useState } from 'react';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import "./styles/CharacterCreator.css";
import { db, storage } from './firebase-config';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth } from './firebase-config';
import { v4 as uuidv4 } from 'uuid';
import CharacterCreatorBuilderSideNav from './CharacterCreatorBuilderComponents/CharacterCreatorBuilderSideNav';
import CharacterCreatorBuilderVisual from './CharacterCreatorBuilderComponents/CharacterCreatorBuilderVisual';
import CharacterCreatorBuilderAi from './CharacterCreatorBuilderComponents/CharacterCreatorBuilderAi';
import CharacterCreatorBuilderVoice from './CharacterCreatorBuilderComponents/CharacterCreatorBuilderVoice';
import CharacterCreatorBuilderAttributes from './CharacterCreatorBuilderComponents/CharacterCreatorBuilderAttributes';

const CharacterCreatorBuilder = () => {
  const { type } = useParams();
  const [selectedTab, setSelectedTab] = useState("visual");
  const [emotionSelected, setEmotionSelected] = useState("happy");
  const [isBlinking, setIsBlinking] = useState(false);
  const [isTalking, setIsTalking] = useState(false);
  const [randomness, setRandomness] = useState(5);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [selectedVoice, setSelectedVoice] = useState({ label: "", value: ["", "", ""] });
  const [pitch, setPitch] = useState(10);
  const [speed, setSpeed] = useState(1);
  const [images, setImages] = useState({
    happy: { default: null, blinking: null, talking: null, blinkingTalking: null },
    sadness: { default: null, blinking: null, talking: null, blinkingTalking: null },
    anger: { default: null, blinking: null, talking: null, blinkingTalking: null },
    confusion: { default: null, blinking: null, talking: null, blinkingTalking: null }
  });
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [friends, setFriends] = useState([]);
  const navigate = useNavigate();
  const validateInputs = () => {
    const errors = {};
    if (!name) errors.name = "Name is required";
    if (!description) errors.description = "Description is required";
    if (!selectedVoice.label) errors.selectedVoice = "Voice is required";

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

const backButton = () => {
  navigate('/create')
}

const createModel = async () => {
  if (!validateInputs()) return;

  setLoading(true);
  const modelID = uuidv4();
  const imageUrls = {};

  try {
    console.log('Starting image upload process...');

    // Upload images and get their URLs
    for (const [emotion, imageTypes] of Object.entries(images)) {
      imageUrls[emotion] = {};
      for (const [type, imageData] of Object.entries(imageTypes)) {
        if (imageData) {
          const imageRef = ref(storage, `characterImages/${modelID}/${emotion}-${type}`);
          console.log(`Uploading image for ${emotion}-${type}...`);
          const response = await fetch(imageData);
          const blob = await response.blob();
          await uploadBytes(imageRef, blob);
          const downloadURL = await getDownloadURL(imageRef);
          imageUrls[emotion][type] = downloadURL;
          console.log(`Uploaded image for ${emotion}-${type}: ${downloadURL}`);
        }
      }
    }

    console.log('All images uploaded, creating model document in Firestore...');

    // Create model document in Firestore
    const payload = {
      name: name,
      description: description,
      voice: selectedVoice.value,
      pitch: pitch,
      speed: speed,
      userId: auth.currentUser.uid,
      randomness: randomness,
      images: imageUrls,
      modelID: modelID, 
      attributes: selectedAttributes,// Include the modelID in the payload
      friends: friends
    };

    await addDoc(collection(db, "characters"), payload);

    setLoading(false);
    alert("Model created successfully!");
    console.log('Model created successfully with payload:', payload);
  } catch (error) {
    console.error("Error creating model:", error);
    setLoading(false);
  }
};


  return (
    <div className='main-container-for-character-builder-window'>
      <div className='main-container-for-character-builder-window-top-container'>
       <button style={{
          backgroundColor: '#f7d962',
          borderRadius: '5px',
          border: '2px solid black',
       }} onClick={backButton}>Back</button>
       <div className='change-title-h1-reformat'>
        <h1 className='title-of-page-creator'> Character Creator {type === 'simple' ? 'Simple' : 'Advanced'}</h1>
        </div>
        <button
          onClick={createModel}
          disabled={loading}
          style={{
            backgroundColor: Object.keys(validationErrors).length > 0 ? 'red' : '#f7d962',
            borderRadius: '5px',
          border: '2px solid black',
            
          }}
        >
          {loading ? "Creating..." : "Create"}
        </button>
        {Object.keys(validationErrors).length > 0 && (
          <div className='validation-errors'>
            {validationErrors.name && <p>{validationErrors.name}</p>}
            {validationErrors.description && <p>{validationErrors.description}</p>}
            {validationErrors.selectedVoice && <p>{validationErrors.selectedVoice}</p>}
          </div>
        )}
      </div>
      <div className='main-container-for-character-builder'>
        <div></div>

        {selectedTab === "visual" && (
          <CharacterCreatorBuilderVisual isBlinking={isBlinking} setIsBlinking={setIsBlinking} 
            isTalking={isTalking} setIsTalking={setIsTalking} setImages={setImages} images={images} 
            emotionSelected={emotionSelected} setEmotionSelected={setEmotionSelected} />
        )}

        {selectedTab === "ai" && (
          <CharacterCreatorBuilderAi randomness={randomness} setRandomness={setRandomness} 
            selectedAttributes={selectedAttributes} setSelectedAttributes={setSelectedAttributes}
            description={description} setDescription={setDescription} name={name} setName={setName} friends={friends} setFriends={setFriends}/>
        )}

        {selectedTab === "voice" && (
          <CharacterCreatorBuilderVoice selectedVoice={selectedVoice} setSelectedVoice={setSelectedVoice}
            pitch={pitch} setPitch={setPitch} speed={speed} setSpeed={setSpeed} />
        )}


        <CharacterCreatorBuilderSideNav isBlinking={isBlinking} setIsBlinking={setIsBlinking} 
          isTalking={isTalking} setIsTalking={setIsTalking} selectedTab={selectedTab} 
          setSelectedTab={setSelectedTab} />
      </div>
    </div>
  );
};

export default CharacterCreatorBuilder;

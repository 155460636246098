import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import "./styles/QuickStart.css";
import Communicate from './Communicate';
import StreamCommunicate from './StreamCommunication/StreamCommunicate';
import { getFunctions, httpsCallable } from 'firebase/functions';

function StartingUp() {
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [isLoadingComplete, setIsLoadingComplete] = useState(false);
    const [preloadedImages, setPreloadedImages] = useState({});
    const location = useLocation();
    const { selectedCharacter, streaming } = location.state || {};

    const functions = getFunctions();
    const textToSpeech = httpsCallable(functions, 'textToSpeech');
    const textToSpeechTurbo = httpsCallable(functions, 'textToSpeechTurbo');
    const emotions = httpsCallable(functions, 'queryHuggingFace');
    const predictFromAI = httpsCallable(functions, 'predictFromAI');
    const speechToText = httpsCallable(functions, 'speechToText');

    const incrementProgress = (amount) => {
        setLoadingProgress(prev => Math.min(prev + amount, 100));
    };

    const apis = [
        async () => {
            const result = await textToSpeech({
                text: "hello",
                voice: { name: "en-US-Casual-k", languageCode: "en-us", ssmlGender: "MALE" },
                audioConfig: { audioEncoding: "MP3", pitch: 10, speakingRate: 1 }
            });
            incrementProgress(20);
        },

        async () => {
            const prompt = {
                context: `hi`,
                messages: [{ role: "user", content: "1" }],
                tokens: 1
            };
            const result = await predictFromAI({ prompt });
            incrementProgress(40);
        },
        async () => {
            const emote = await emotions({
                inputs: "hi",
                parameters: {}
            });
            incrementProgress(20);
        },
        async () => {
            try {
                const dummyAudio = "UklGRkoAAABXQVZFZm10IBAAAAABAAEAESsAACJWAAACABAAZGF0YQAAAAA=";
                const result = await speechToText({ audioBuffer: dummyAudio });
            } catch (error) {
                console.error("Speech to text error", error);
            } finally {
                incrementProgress(20);
            }
        }
    ];

    const preloadImage = (src) => {
        return new Promise((resolve, reject) => {
            if (!src) {
                resolve(null); // Skip if the src is null or undefined
                return;
            }
            const img = new Image();
            img.src = src;
            img.onload = () => resolve(src);
            img.onerror = reject;
        });
    };

    const preloadImages = async (character) => {
        const emotions = ['anger', 'confusion', 'happy', 'sadness'];
        const states = ['default', 'blinking', 'talking', 'blinkingTalking'];

        const imagePromises = emotions.flatMap(emotion =>
            states.map(state =>
                preloadImage(character.images[emotion][state])
                    .then(src => ({ emotion, state, src }))
            )
        );

        const loadedImages = await Promise.all(imagePromises);
        const images = loadedImages.reduce((acc, { emotion, state, src }) => {
            if (!acc[emotion]) acc[emotion] = {};
            if (src) acc[emotion][state] = src; // Only add if src is not null
            return acc;
        }, {});

        setPreloadedImages(images);
        incrementProgress(20); // Assuming this step contributes to progress
    };

    useEffect(() => {
        const callApis = async () => {
            try {
                await Promise.all(apis.map(api => api()));
                await preloadImages(selectedCharacter);
                setIsLoadingComplete(true);
            } catch (error) {
                console.error("Error loading resources", error);
            }
        };

        callApis();
    }, [selectedCharacter]);

    if (isLoadingComplete && !streaming) {
        return <Communicate selectedCharacter={selectedCharacter} />;
    } else if (isLoadingComplete && streaming) {
        return <StreamCommunicate selectedCharacter={selectedCharacter} preloadedImages={preloadedImages} />;
    }

    return (
        <div className='Starting-Up-Display'>
            <div className='starting-up-text'>Starting up</div>
            <div className="counter">{loadingProgress.toFixed(0)}%</div>
            <div className='progressbar'>
                <span className='progress' style={{ width: `${loadingProgress}%` }}></span>
            </div>
        </div>
    );
}

export default StartingUp;

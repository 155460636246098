import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function PrivacyPolicy() {
  useEffect(() => {
    window.location.href = "/privacy-policy.html"; // Redirects to the static HTML file
  }, []);

  return null; // Component doesn't render anything
}

export default PrivacyPolicy;

import React from 'react';

const TwitchLogin = () => {
  const clientId = 'c0i4yt83s4fmr4d76renpisjqnd8cy';
  const redirectUri = encodeURIComponent('https://chaoticcompanions.com/twitch');//https://ai-backend-415806--twitch-integration-839ifyiy.web.app/twitch http://localhost:3000/twitch
  const responseType = 'token'; //https://chaoticcompanions.com/twitch
  const scopes = encodeURIComponent('user:read:email channel:manage:redemptions');

  const twitchLoginUrl = `https://id.twitch.tv/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scopes}`;

  return (
    <div>
      <a className="btn connect-twitch-button"href={twitchLoginUrl}>Connect with Twitch</a>
    </div>
  );
};

export default TwitchLogin;

import React from 'react';
import { PopupProvider } from './PopupContext';
import StartingUp from './StartingUp';

const StartingUpWrapper = () => {
  return (
    <PopupProvider>
      <StartingUp />
    </PopupProvider>
  );
};

export default StartingUpWrapper;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/LandingPage.css';
import imageSrc from './styles/Logo-yellow.png';
import forest from './styles/forest.jpg';
import space from './styles/space.png';
import bananas from './styles/bananas.png';
import LandingPageForest from './LandingPageForest';

function LandingPage() {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((current) => (current + 1) % 3);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  const handleNavigate = () => {
    navigate('/signin');
  };

  const handleRadioChange = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="main-page">
      <div className="image-above-a3testdele3">
        <img src={forest} alt="Descriptive Alt Text" /> {/* Example image */}
      </div>
      <div className="a3testdele3">
        <LandingPageForest />
      </div>

      <div className="top-section">
        <p className="main-text fade-in-up">Banana Wolf</p>
        <div className="logo-and-ai">
          <img className="logo" src={imageSrc} alt="Banana Wolf Logo" />
          <p className="main-text fade-in-up">AI</p>
        </div>
      </div>

      <div className="bottom-section">
        <div className="another-container">
          <h1 className="mini-title">New Way To Stream</h1>
          <p className="small-text">Unleash your creativity and bring your AI characters to life with Banana Wolf AI!</p>
        </div>
        <div className="button-container">
          <button className="button-start" onClick={handleNavigate}>Get Started</button>
        </div>
      </div>

      <div className="container images3">
        <input type="radio" name="slider" id="item-1" checked={activeIndex === 0} onChange={() => handleRadioChange(0)} />
        <input type="radio" name="slider" id="item-2" checked={activeIndex === 1} onChange={() => handleRadioChange(1)} />
        <input type="radio" name="slider" id="item-3" checked={activeIndex === 2} onChange={() => handleRadioChange(2)} />
        <div className="cards">
          <label className="carousel-card" htmlFor="item-1" id="song-1">
            <img src={forest} alt="forest" className="preview-image" />
            <div className="image-text">Design</div>
          </label>
          <label className="carousel-card" htmlFor="item-2" id="song-2">
            <img src={space} alt="space" className="preview-image" />
            <div className="image-text">Explore</div>
          </label>
          <label className="carousel-card" htmlFor="item-3" id="song-3">
            <img src={bananas} alt="banana" className="preview-image" />
            <div className="image-text">Enjoy</div>
          </label>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;

import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { usePopup } from '../PopupContext'; // Import the Popup context
import StreamPopUp from './StreamPopUp';
import '../styles/StreamCommunicate.css';
import StreamConversationManager from './StreamConversationManager';
import StreamSpeechToText from './StreamSpeechToText'; // Import StreamSpeechToText
import StreamTextToSpeech from './StreamTextToSpeech'; // Import StreamTextToSpeech
import StreamTwitchIntegration from './StreamTwitchIntegration';
import { collection, addDoc, updateDoc, doc, Timestamp } from 'firebase/firestore';
import TrackUsage from '../TrackUsage';
import { Tooltip } from 'react-tooltip';
import {db} from '../firebase-config';
import { getAuth } from 'firebase/auth';

const StreamCommunicate = ({ selectedCharacter, selectedModel, selectedRealm, preloadedImages }) => {
  const { openPopup, closePopup, popup, popupRoot } = usePopup();
  const location = useLocation();
  const [Image, setImage] = useState('');
  const originalColor = '#02ff00'; // Original starting color
  const [backgroundColor, setBackgroundColor] = useState(originalColor);
  const [imageSize, setImageSize] = useState(100); // Image size in percentage
  const [isListening, setIsListening] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // New state for loading
  const [onTranscription, setOnTranscription] = useState('');
  const [conversation, setConversation] = useState([]);
  const [gotResponse, setGotResponse] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false); // State to track if audio is playing
  const [emotion, setEmotion] = useState('');
  const [isBlinking, setIsBlinking] = useState(false);

  const [forcedEmotion, setForcedEmotion] = useState(''); // New state for forced emotion
  const [listenRandomly, setListenRandomly] = useState(false); // New state for listenRandomly
  const [randomIntervalInput, setRandomIntervalInput] = useState(5); // Default interval input value is 5
  const [randomInterval, setRandomInterval] = useState(null); // No interval set initially
  const [timer, setTimer] = useState(0); // Timer for next listen

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const [imageSrc, setImageSrc] = useState(preloadedImages.happy.default);

  // Twitch hooks
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [userId, setUserId] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const [redemptions, setRedemptions] = useState([]);
  const [token, setToken] = useState('');
  const [rewardIds, setRewardIds] = useState([]); // Use an array for reward IDs
  const [customRewards, setCustomRewards] = useState([]); // Use an array for custom rewards
  const [displayedMessage, setDisplayedMessage] = useState('');
  const [messageQueue, setMessageQueue] = useState([]); // Queue for messages
  const [isProcessingMessage, setIsProcessingMessage] = useState(false); // State to track message processing
const initialRender = useRef(true);
 

const docRef = useRef(null); // Store the document reference


useEffect(() => {
  const addOrUpdateSessionData = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user && !user.isAnonymous && conversation.length != 0) {
      const now = Timestamp.now().toDate();
      const date = now.toLocaleDateString();
      const time = now.toLocaleTimeString();

      try {
        if (initialRender.current) {

          // Initial render: create the document
          const docRefResponse = await addDoc(collection(db, 'userSessionData'), {
            userId: user.uid,
            characterId: selectedCharacter.id, // replace with actual character ID
            date: date,
            time: time,
            conversation: conversation,
          });
          docRef.current = docRefResponse; // Store document reference
          //console.log("Session data added on initial load");
          initialRender.current = false;
        } else if (docRef.current) {
          // Subsequent updates: update the conversation field only
          await updateDoc(doc(db, 'userSessionData', docRef.current.id), {
            conversation: conversation
          });
          //console.log("Conversation updated in session data");
        }
      } catch (error) {
        console.error("Error with session data: ", error);
      }
    } else {
      //console.log("User is either not signed in or is logged in anonymously.");
    }
  };

  addOrUpdateSessionData();
}, [conversation]);


  const processQueue = async () => {
    if (isLoading || isListening || isPlaying || messageQueue.length === 0 || conversation.length % 2 !== 0) {
      return;
    }
    setIsProcessingMessage(true);
    let nextMessage = messageQueue[0];
    if (nextMessage === displayedMessage) {
      nextMessage += ' ';
    }
    setDisplayedMessage(nextMessage);
    setMessageQueue(prevQueue => prevQueue.slice(1)); // Remove the processed message from the queue
    setIsProcessingMessage(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      processQueue();
    }, 1000);

    return () => clearInterval(interval);
  }, [isLoading, isListening, isPlaying, messageQueue, isProcessingMessage, conversation.length]);

  useEffect(() => {
    if (displayedMessage && displayedMessage.trim() !== "") {
      setOnTranscription(displayedMessage);
    }
  }, [displayedMessage]);

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const blink = async () => {
    while (true) {
      setIsBlinking(false);
      await delay(3500);
      setIsBlinking(true);
      await delay(700);
    }
  };

  useEffect(() => {
    blink();
  }, [selectedCharacter]);

  useEffect(() => {
    let interval;
    if (listenRandomly && randomInterval) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            RecordAudio();
            return randomInterval * 60;
          }
          return prevTimer - 1;
        });
      }, 1000);
    } else {
      setTimer(randomInterval * 60);
    }
    return () => clearInterval(interval);
  }, [listenRandomly, randomInterval]);

  const getEmotionImage = (currentEmotion, isTalking, isBlinking) => {
    if (currentEmotion === 'anger' && preloadedImages.anger.default) {
      return (isTalking && preloadedImages.anger.talking) ?
        ((isBlinking && preloadedImages.anger.blinking) ? preloadedImages.anger.blinkingTalking : preloadedImages.anger.talking) :
        ((isBlinking && preloadedImages.anger.blinking) ? preloadedImages.anger.blinking : preloadedImages.anger.default);
    } else if (currentEmotion === 'confusion' && preloadedImages.confusion.default) {
      return (isTalking && preloadedImages.confusion.talking) ?
        ((isBlinking && preloadedImages.confusion.blinking) ? preloadedImages.confusion.blinkingTalking : preloadedImages.confusion.talking) :
        ((isBlinking && preloadedImages.confusion.blinking) ? preloadedImages.confusion.blinking : preloadedImages.confusion.default);
    } else if (currentEmotion === 'sadness' && preloadedImages.sadness.default) {
      return (isTalking && preloadedImages.sadness.talking) ?
        ((isBlinking && preloadedImages.sadness.blinking) ? preloadedImages.sadness.blinkingTalking : preloadedImages.sadness.talking) :
        ((isBlinking && preloadedImages.sadness.blinking) ? preloadedImages.sadness.blinking : preloadedImages.sadness.default);
    } else if (preloadedImages.happy.default) {
      return (isTalking && preloadedImages.happy.talking) ?
        ((isBlinking && preloadedImages.happy.blinking) ? preloadedImages.happy.blinkingTalking : preloadedImages.happy.talking) :
        ((isBlinking && preloadedImages.happy.blinking) ? preloadedImages.happy.blinking : preloadedImages.happy.default);
    }
  };

  useEffect(() => {
    const currentEmotion = forcedEmotion || emotion.toLowerCase();
    setImageSrc(getEmotionImage(currentEmotion, isPlaying, isBlinking));
  }, [emotion, isBlinking, isPlaying, forcedEmotion, preloadedImages]);

  useEffect(() => {
    setImageSrc(preloadedImages.happy.default);
  }, [selectedModel, selectedRealm]);

  useEffect(() => {
    if (popupRoot) {
      popupRoot.render(
        <StreamPopUp
          selectedModel={selectedModel}
          selectedRealm={selectedRealm}
          Image={imageSrc}
          backgroundColor={backgroundColor}
          imageSize={imageSize - 25}
        />
      );
    }
  }, [popupRoot, backgroundColor, imageSrc, selectedModel, selectedRealm, imageSize]);

  useEffect(() => {
    if (location.pathname !== '/startingup' && popup) {
      closePopup();
    }
  }, [location.pathname, popup, closePopup]);

  useEffect(() => {
    return () => {
      if (popup) {
        closePopup();
      }
    };
  }, [popup, closePopup]);

  const handleColorChange = (event) => {
    setBackgroundColor(event.target.value);
  };

  const handleSizeChange = (event) => {
    setImageSize(event.target.value);
  };

  const handlePercentChange = (event) => {
    setImageSize(event.target.value);
  };

  const resetSize = () => {
    setImageSize(100);
  };

  const resetColor = () => {
    setBackgroundColor(originalColor);
  };

  const RecordAudio = () => {
    if (!isPlaying && !isListening && !isLoading) {
      setIsLoading(true);
      setIsListening(true);
    }
  };

  useEffect(() => {
    if (isListening) {
      setIsLoading(true);
    }
  }, [isListening]);

  useEffect(() => {
    if (isPlaying) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isPlaying]);

  const handleEmotionChange = (emotion) => {
    setForcedEmotion(emotion);
    setDropdownOpen(false);
  };

  const handleRandomlyChange = () => {
    setListenRandomly(!listenRandomly);
  };

  const handleIntervalChange = (event) => {
    const value = Math.max(1, parseInt(event.target.value, 10));
    setRandomIntervalInput(value);
  };

  const handleSetInterval = () => {
    setRandomInterval(randomIntervalInput);
    setTimer(randomIntervalInput * 60);
  };

  useEffect(() => {
    //console.log('onTranscription:', onTranscription);
    if (onTranscription !== '') {
      setConversation([...conversation, { content: onTranscription }]);
      setOnTranscription(''); // Reset onTranscription
    }
  }, [onTranscription]);

  useEffect(() => {}, [conversation]);

  useEffect(() => {

    //console.log("Conversation", conversation)
  }, [conversation]);

  const renderConversation = () => {
    return conversation.map((message, index) => (
      <div key={index} className={`message ${index % 2 === 0 ? 'user' : 'model'}`}>
        <strong>{index % 2 === 0 ? 'Me' : selectedCharacter.name}: </strong>{message.content}
      </div>
    ));
  };

  return (
    <div className='StreamCommunicateMain'>
      <div className='StreamCommunicateComponents'>
        <h1 className='StreamCommunicateTitle'>Stream</h1>
        <button className='StreamCommunicatePopUp btn btn-info' onClick={openPopup} disabled={location.pathname !== '/startingup'}>
          START <i  style={{marginLeft: '4px', fontSize: '1.3rem'}}className="fa-regular fa-circle-question" data-tooltip-id="tooltip" 
                        data-tooltip-content={'1. Make a Window Capture source in your stream software (OBS or Streamlabs) and capture this new popped-out window.\n\n2. Hold ALT and drag down the top to get rid of the link\n\n3. Right click on the window capture in the streaming software, Click filters, and apply a Chroma Key to get rid of the green screen (adjust the transparency if the character is see-through)\n\n4. Move the character to their new home on your stream, and they\re ready for action!'}></i> 
                        <Tooltip id="tooltip"  style={{ maxWidth: '400px', whiteSpace: 'pre-wrap', wordWrap: 'break-word', zIndex: '10',fontSize: '1rem'  }}/>
        </button>
        <div className='ContainerForControlsAndPreview'>
          <div className='StreamCommunicateControlPanel'>
            <h1 className='controlsTitle'>Conversation</h1>
            <div className='conversationPanel' style={{ maxHeight: "400px",minHeight: '400px', overflowY: 'auto', marginLeft: '10px',padding: '10px', border: '1px black solid', margin: '10px' }}>
              {renderConversation()}
            </div>
            <div className='streamCharacterControls' style={{ maxHeight: "300px",overflowY: 'auto' }}>
              <label htmlFor="colorPicker">Channel Point Redemptions</label>
              {displayedMessage && displayedMessage !== "" && (
                <div style={{ color: 'black', padding: '10px' }}>
                  {displayedMessage}
                </div>
              )}
              <ul style={{ color: 'black' }}>
                {redemptions && redemptions.map((redemption, index) => (
                  <li key={index}>
                    {redemption.user_name}: {redemption.user_input || redemption.reward.prompt}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className='containerForPreview'>
            <h1 className='StreamCommunicatePreviewText'>Preview</h1>
            <div className='StreamCommunicatePreview' style={{ backgroundColor: backgroundColor }}>
              <img className='StreamCommunicateImage' src={imageSrc} alt="Preview" style={{ width: `${imageSize - 25}%`, height: 'auto' }} />
            </div>
          </div>
          <div className='StreamCommunicateControlPanel'>
            <h1 className='controlsTitle'>Controls</h1>
            <div className='streamCharacterControls'>
              <h3>Character Controls</h3>
              <div className="controlItemstream">
                <label htmlFor="colorPicker">Background Color: </label>
                <input
                  type="color"
                  id="colorPicker"
                  value={backgroundColor}
                  onChange={handleColorChange}
                />
                <button onClick={resetColor} className='resetColorButton resetButtonStream'><i className="fa-solid fa-arrow-rotate-left"></i></button>
              </div>
              <div className="controlItemstream">
                <label htmlFor="sizeSlider">Image Size: </label>
                <input
                  type="range"
                  id="sizeSlider"
                  min="10"
                  max="200"
                  value={imageSize}
                  onChange={handleSizeChange}
                />
                <input
                  type="number"
                  id="percentInput"
                  min="10"
                  max="200"
                  value={imageSize}
                  onChange={handlePercentChange}
                />%
                <button onClick={resetSize} className='resetSizeButton resetButtonStream'><i className="fa-solid fa-arrow-rotate-left"></i></button>
              </div>
              <div className="controlItemstream">
                <label htmlFor="forceEmotion">Force Emotion: </label>
                <div className="dropdown">
      <button
        className="btn btn-secondary dropdown-toggle"
        type="button"
        onClick={toggleDropdown}
        aria-expanded={dropdownOpen}
      >
        {forcedEmotion || 'Not Forced'}
      </button>
      <ul className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}>
        <li><button className="dropdown-item" onClick={() => handleEmotionChange('anger')}>Anger</button></li>
        <li><button className="dropdown-item" onClick={() => handleEmotionChange('confusion')}>Confusion</button></li>
        <li><button className="dropdown-item" onClick={() => handleEmotionChange('happy')}>Happy</button></li>
        <li><button className="dropdown-item" onClick={() => handleEmotionChange('sadness')}>Sadness</button></li>
        <li><button className="dropdown-item" onClick={() => handleEmotionChange('')}>Not Forced</button></li>
      </ul>
    </div>
              </div>
              <div className="controlItemstream">
                <label htmlFor="listenRandomly">Listen Periodically: </label>
                <button
                  className={`btn btn-${listenRandomly ? 'success' : 'danger'}`}
                  onClick={handleRandomlyChange}
                >
                  {listenRandomly ? 'ON' : 'OFF'}
                </button>
              </div>
              {listenRandomly && (
                <>
                  <div className="controlItemstream">
                    <label htmlFor="randomInterval">Listen Every: </label>
                    <input
                      type="number"
                      id="randomInterval"
                      min="1"
                      value={randomIntervalInput}
                      onChange={handleIntervalChange}
                      style={{
                        width: '80px',
                      }}
                    />
                    <label htmlFor="randomInterval">minutes</label>
                    <button className='btn btn-primary' onClick={handleSetInterval}>Set</button>
                  </div>
                  <div className="controlItemstream">
                    <label>Time to next listen: {Math.floor(timer / 60)}:{('0' + (timer % 60)).slice(-2)}</label>
                  </div>
                </>
              )}
            </div>
            <div className='streamCharacterControls'>
              <h3>Stream Controls</h3>
              <div>logged in user: {username}</div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>Streaming Status: {isStreaming ? 'Live' : 'Offline'}</span>
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: isStreaming ? 'green' : 'red',
                    marginLeft: '5px',
                  }}
                ></div>
              </div>
            </div>
            <div className='streamCharacterControls'
              style={{
                overflowY: 'auto'
              }}
            >
              <label htmlFor="TwitchRedeems">Custom Channel Redeems: </label>
              <ul style={{ color: 'black', maxHeight: '200px' }}>
                {customRewards && customRewards.map((reward, index) => (
                  <li key={index}>
                    <strong>Title:</strong> {reward.title}<br />
                    <strong>Prompt:</strong> {reward.prompt}<br />
                    <strong>Cost:</strong> {reward.cost}<br />
                    <strong>User Input Required:</strong> {reward.is_user_input_required ? 'Yes' : 'No'}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <button className='StreamCommunicateListenButton' onClick={RecordAudio}>
        {(isLoading || isListening || isPlaying) ? (
          <div className="spinner-border " role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <i className="fa-solid fa-microphone "></i>
        )}
      </button>
      <StreamConversationManager
        setConversation={setConversation}
        conversation={conversation}
        speechToTextMessage={onTranscription}
        selectedCharacter={selectedCharacter}
        setGotResponse={setGotResponse}
        setEmotion={setEmotion}
      />
      <StreamSpeechToText
        isListening={isListening}
        setIsListening={setIsListening}
        setOnTranscription={setOnTranscription}
      />
      {conversation.length > 0 && conversation.length % 2 === 0 && (
        <StreamTextToSpeech
          message={conversation[conversation.length - 1]}
          selectedCharacter={selectedCharacter}
          setIsPlaying={setIsPlaying}
        />
      )}
      <StreamTwitchIntegration 
        username={username} setUsername={setUsername}
        isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}
        userId={userId} setUserId={setUserId}
        isStreaming={isStreaming} setIsStreaming={setIsStreaming}
        redemptions={redemptions} setRedemptions={setRedemptions}
        token={token} setToken={setToken}
        rewardIds={rewardIds} setRewardIds={setRewardIds}
        customRewards={customRewards} setCustomRewards={setCustomRewards}
        setMessageQueue={setMessageQueue}
        displayedMessage={displayedMessage} setDisplayedMessage={setDisplayedMessage}
      />
      <TrackUsage />
    </div>
  );
}

export default StreamCommunicate;

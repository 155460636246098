import React, { useState, useEffect, useRef } from 'react';
import "./styles/speechToText.css";
import TestAudioComponent from './testAudioComponent';

const SpeechToText = ({ setSpeechToTextMessage, gotResponse, setGotResponse, setIsAudioMuted, isPlaying, initialLoad, setInitialLoad }) => {
  const [text, setText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const canvasRef = useRef(null);

  const [isRunning, setIsRunning] = useState(false);
  const isRunningRef = useRef(isRunning);

  useEffect(() => {
    setIsRunning(true);
    isRunningRef.current = true;
  }, []);

  useEffect(() => {
    if (isRunning) {
      setupAudioProcessing();
    }
  }, [isRunning]);

  const setupAudioProcessing = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.log('Audio processing not supported');
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 256;
      const dataArray = new Uint8Array(analyser.frequencyBinCount);
      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);

      const updateDecibels = () => {
        analyser.getByteFrequencyData(dataArray);
        const sum = dataArray.reduce((a, b) => a + b, 0);
        const average = sum / dataArray.length;
        drawVolumeMeter(average);
        requestAnimationFrame(updateDecibels);
      };

      updateDecibels();
    } catch (error) {
      console.log('Error accessing microphone', error);
    }
  };

  const drawVolumeMeter = (volume) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;

    ctx.clearRect(0, 0, width, height);
    ctx.fillStyle = '#3498db';
    const volumeWidth = volume * width;
    ctx.fillRect(0, 0, volumeWidth, 10);

    ctx.strokeStyle = '#ff0000';
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(width - ctx.lineWidth / 2, 0);
    ctx.lineTo(width - ctx.lineWidth / 2, 10);
    ctx.stroke();
  };

  drawVolumeMeter(0);

  const toggleListening = () => {
    if (isRunning) {
      setIsRunning(false);
      isRunningRef.current = false;
      setIsAudioMuted(true);
    } else {
      isRunningRef.current = true;
      setIsRunning(true);
      setIsAudioMuted(false);
    }
  };

  const handleTranscription = (transcription) => {
    setText(transcription);
    setSpeechToTextMessage(transcription);
    setGotResponse(true);
    setIsLoading(false);
  };

  return (
    <div className='listening-audio-meter'>
    
      <div className='spinning' style={{ marginLeft: isLoading ? '25px' : '0' }}>
        <button onClick={toggleListening} className='btn btn-purple start-listening-button' style={{ marginTop: '5px' }}>
          {isRunning ? 'Stop Listening' : 'Start Listening'}
        </button>
        {isLoading && (
          <div className="spinner" style={{ border: '4px solid #f3f3f3', borderTop: '4px solid #3498db', borderRadius: '50%', width: '20px', height: '20px', animation: 'spin 2s linear infinite', marginLeft: '5px', display: 'inline-block' }}></div>
        )}
      </div>
      {isRunning && <TestAudioComponent onTranscription={handleTranscription} initialLoad={initialLoad} setInitialLoad={setInitialLoad}/>}
    </div>
  );
};

export default SpeechToText;

import React, { useState, useRef, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

const TestAudioComponent = ({ onTranscription, isPlaying, initialLoad,setInitialLoad }) => {
  const [transcription, setTranscription] = useState('');
  const [decibels, setDecibels] = useState(0);
  
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const analyserRef = useRef(null);
  const audioContextRef = useRef(null);
  const sourceRef = useRef(null);
  const animationFrameIdRef = useRef(null);
  const silenceTimeoutRef = useRef(null);
  const hasExceededThresholdRef = useRef(false);
  const recordingRef = useRef(true); // Set initial state to true to start recording
  const silenceDuration = 500;
  const decibelCutoff = 25;

  useEffect(() => {
    if(!initialLoad){
     
    startRecording();
    hasExceededThresholdRef.current = false;

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current);
      }
      if (silenceTimeoutRef.current) {
        clearTimeout(silenceTimeoutRef.current);
      }
    };

  } else{
    setTranscription("This is a system message. Greet the user and describe yourself");
    onTranscription("This is a system message.  Greet the user and describe yourself")
    setInitialLoad(false)
  }
  }, [initialLoad]);

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    analyserRef.current = audioContextRef.current.createAnalyser();
    analyserRef.current.fftSize = 256;
    const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);

    sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
    sourceRef.current.connect(analyserRef.current);

    mediaRecorderRef.current = new MediaRecorder(stream);
    mediaRecorderRef.current.ondataavailable = event => {
      audioChunksRef.current.push(event.data);
    };
    mediaRecorderRef.current.onstop = handleStop;
    mediaRecorderRef.current.start();

    const updateDecibels = () => {
      analyserRef.current.getByteFrequencyData(dataArray);
      const sum = dataArray.reduce((a, b) => a + b, 0);
      const average = sum / dataArray.length;
      setDecibels(average);

      if (average > decibelCutoff) {
       // console.log("threshold met");
        hasExceededThresholdRef.current = true;
      }

      if (average < 10 && hasExceededThresholdRef.current) {
        if (!silenceTimeoutRef.current) {
          silenceTimeoutRef.current = setTimeout(() => {
           // console.log("stopping");
            stopRecording();
            hasExceededThresholdRef.current = false;
            silenceTimeoutRef.current = null;
          }, silenceDuration);
        }
      } else {
        if (silenceTimeoutRef.current) {
          clearTimeout(silenceTimeoutRef.current);
          silenceTimeoutRef.current = null;
        }
      }

      animationFrameIdRef.current = requestAnimationFrame(updateDecibels);
    };

    updateDecibels();
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
    }
    if (animationFrameIdRef.current) {
      cancelAnimationFrame(animationFrameIdRef.current);
    }
  };

  const handleStop = async () => {
    //console.log("handling stop");
    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
    audioChunksRef.current = [];
    const base64Audio = await convertBlobToBase64(audioBlob);

    if (recordingRef.current) {
      sendToSpeechToText(base64Audio);
    }
  };

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
    });
  };

  const sendToSpeechToText = async (base64Audio) => {
    const functions = getFunctions();
    const speechToText = httpsCallable(functions, 'speechToText');
    try {
      const result = await speechToText({ audioBuffer: base64Audio });
      setTranscription(result.data.text);
      onTranscription(result.data.text);
      if (recordingRef.current && !isPlaying) {
        startRecording();
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
     
      <div>
      
      </div>
    </div>
  );
};

export default TestAudioComponent;

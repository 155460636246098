import React, { useEffect } from "react";
import { getFunctions, httpsCallable } from 'firebase/functions';

const StreamConversationManager = ({ setConversation, conversation, speechToTextMessage, selectedCharacter, setGotResponse, setEmotion }) => {
     
    useEffect(() => {
        if (speechToTextMessage) {
            //console.log("Use Effect called on transcription") 
            sendOtherMessage();
        }
    }, [speechToTextMessage]);

    async function sendOtherMessage() {
        const functions = getFunctions();
        const predictFromAI = httpsCallable(functions, 'predictFromAI');

        // Add user message to conversation
        let updatedConversation = [...conversation, { role: 'user', content: speechToTextMessage }];

        const prompt = {
            context: `your name is ${selectedCharacter.name}, ${selectedCharacter.description}. Respond in a conversational manner with natural pauses, laughs, and expressions. Use phrases like "uh-huh", "hmm", "let me think", "haha", "oh really?", etc. (15 WORDS MAXIMUM RESPONSE FOR EVERY RESPONSE, NO EMOJIS)`, // Context for the conversation
            messages: updatedConversation,
            tokens: 40
        };

        try {
            const result = await predictFromAI({ prompt });
            //console.log("Received AI response")
            const { predictions } = result.data;
            const emotions = httpsCallable(functions,"queryHuggingFace" )
            //console.log("Received Emotion Token")
            const emote = await emotions({
                inputs: result.data.predictions,
                parameters: {}
              });
               
                setEmotion(emote.data[0].label)
            //  console.log("Result", predictions)
            // Add AI response to conversation
            updatedConversation = [...updatedConversation, { role: "assistant", content: predictions }];
            
            setConversation(updatedConversation); // Update the conversation state
            setGotResponse(true);

          //  console.log("Updated Conversation: ", updatedConversation); // Log the conversation
        } catch (error) {
            console.error("Error calling the Cloud Function:", error.message);
        }
    }

    return <></>;
}

export default StreamConversationManager;

import React, { useEffect, useRef, useState } from 'react';
import './styles/aiCommunicate.css';

import angry_closed from './styles/test-images/angry_eyesclosed_mouthclosed.png';
import angry_closed_talk from './styles/test-images/angry_eyesclosed_mouthopen.png';
import angry_open from './styles/test-images/angy_eyesopen_mouthclosed.png';
import angry_open_talk from './styles/test-images/angy_eyesopen_mouthopen.png';

import confused_closed from './styles/test-images/confused_eyesclosed_mouthclosed.png';
import confused_open from './styles/test-images/confused_eyesopen_mouthclosed.png';
import confused_closed_talk from './styles/test-images/confused_eyesclosed_mouthopen.png';
import confused_open_talk from './styles/test-images/confused_eyesopen_mouthopen.png';

import happy_closed from './styles/test-images/happy_eyesclosed_mouthclosed.png';
import happy_closed_talk from './styles/test-images/happy_eyesclosed_mouthopen.png';
import happy_open from './styles/test-images/happy_eyesopen_mouthclosed.png';
import happy_open_talk from './styles/test-images/happy_eyesopen_mouthopen.png';

import sad_closed from './styles/test-images/sad_eyesclosed_mouthclosed.png';
import sad_open from './styles/test-images/sad_eyesopen_closedmouth.png';
import sad_closed_talk from './styles/test-images/sad_eyesclosed_mouthopen.png';
import sad_open_talk from './styles/test-images/sad_eyesopen_openmouth.png';

function Visual({ conversation, isPlaying, emotion, selectedModel, background }) {
    const messagesEndRef = useRef(null);
    const threespaceRef = useRef(null);
    const [currentPromptIndex, setCurrentPromptIndex] = useState(0);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [hideObject, setHideObject] = useState(false);
    const [hideObject2, setHideObject2] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const [currentEmotion, setCurrentEmotion] = useState('');
    const [isBlinking, setIsBlinking] = useState(false);
    const [lastAuthor, setLastAuthor] = useState('');
    const [imageScale, setImageScale] = useState(1);
    const [showPrompts, setShowPrompts] = useState(false);
    const [showFullscreenPrompts, setShowFullscreenPrompts] = useState(false);

    const prompts = [
        'Marry Me',
        'Please go away',
        'I\'m so much better than you are',
        'Sing me a song',
        'Can we be friends',
        'It\'s not like I like you or anything',
        'you have a dumb face',
        'can we be, um, friends?',
        'Tell me to be angry',
        'tell me to be sad',
        'tell me to be confused'
    ];

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom(); // Scroll to bottom every time the conversation updates
    }, [conversation]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentPromptIndex((prevIndex) => (prevIndex + 1) % prompts.length);
        }, 6000);

        return () => clearInterval(interval);
    }, [prompts.length]);

    useEffect(() => {
        const handleFullscreenChange = () => {
            if (!document.fullscreenElement) {
                setIsFullscreen(false);
                setImageScale(1); // Reset the image scale when exiting fullscreen
            } else {
                setHideObject(false);
                setHideObject2(false);
                setIsFullscreen(true);
            }
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
        document.addEventListener('mozfullscreenchange', handleFullscreenChange);
        document.addEventListener('MSFullscreenChange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
            document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
        };
    }, []);

    const toggleFullscreen = () => {
        if (!document.fullscreenElement) {
            threespaceRef.current.requestFullscreen().catch(err => {
                console.error(err);
            });
        } else {
            document.exitFullscreen().catch(err => {
                console.error(err);
            });
        }
    };

    const toggleHideChat = () => {
        setHideObject(!hideObject);
    };

    const toggleHideChat2 = () => {
        setHideObject2(!hideObject2);
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    useEffect(() => {
        if (isPlaying) {
            setCurrentEmotion(capitalizeFirstLetter(emotion));
        }
    }, [isPlaying, emotion]);

    useEffect(() => {
        const blinkInterval = setInterval(() => {
            setIsBlinking(false);
            setTimeout(() => setIsBlinking(true), 3000); // Blink after 3 seconds
        }, 3200); // Total cycle of 4 seconds (3 seconds open, 1 second blink)

        return () => clearInterval(blinkInterval);
    }, []);

    useEffect(() => {
        if (selectedModel.name === "Great Bob" || selectedModel.name === "Suspicious Sally") {
            if (emotion.toLowerCase() === 'sadness') {
                if (isPlaying) {
                    setImageSrc(isBlinking ? sad_closed_talk : sad_open_talk);
                } else {
                    setImageSrc(isBlinking ? sad_closed : sad_open);
                }
            } else if (emotion.toLowerCase() === 'anger') {
                if (isPlaying) {
                    setImageSrc(isBlinking ? angry_closed_talk : angry_open_talk);
                } else {
                    setImageSrc(isBlinking ? angry_closed : angry_open);
                }
            } else if (emotion.toLowerCase() === 'confusion') {
                if (isPlaying) {
                    setImageSrc(isBlinking ? confused_closed_talk : confused_open_talk);
                } else {
                    setImageSrc(isBlinking ? confused_closed : confused_open);
                }
            } else {
                if (isPlaying) {
                    setImageSrc(isBlinking ? happy_closed_talk : happy_open_talk);
                } else {
                    setImageSrc(isBlinking ? happy_closed : happy_open);
                }
            }
        } else {
            const fallbackSrc = selectedModel?.emotions?.[currentEmotion] || selectedModel?.mainImage || '';
            setImageSrc(fallbackSrc);
        }
    }, [selectedModel, currentEmotion, isPlaying, isBlinking]);

    const updatedConversation = conversation.slice(1).map((msg, index) => {
        const author = index % 2 === 0 ? selectedModel.name : 'Me';
        return { ...msg, author };
    });

    const increaseImageSize = () => {
        setImageScale(prevScale => prevScale * 1.1);
    };

    const decreaseImageSize = () => {
        setImageScale(prevScale => prevScale / 1.1);
    };

    return (
        <div className="middle-manager">
            <div></div>
            <div className='images'>
                <button onClick={toggleFullscreen} className="fullscreen-button">Toggle Fullscreen</button>
                <div 
                    className="visual-image" 
                    ref={threespaceRef} 
                    style={{ 
                        position: 'relative', 
                        width: '800px', 
                        height: '450px', // 16:9 aspect ratio
                        backgroundImage: `url(${background})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                >
                    {imageSrc && (
                        <img 
                            src={imageSrc} 
                            alt={'Model emotion image'} 
                            onError={() => setImageSrc(selectedModel?.emotions?.[selectedModel.mainImage] || '')}
                            style={{ 
                                position: 'absolute', 
                                top: '50%', 
                                left: '50%', 
                                transform: `translate(-50%, -50%) scale(${imageScale})`,
                                height: '450px', 
                                width: 'auto'
                            }} 
                        />
                    )}
                    {isFullscreen && (
                        <div className="fullscreen-controls">
                            <button onClick={increaseImageSize} className="btn btn-primary">+</button>
                            <button onClick={decreaseImageSize} className="btn btn-primary">-</button>
                        </div>
                    )}
                    <div className={`prompt-box2 ${isFullscreen && !hideObject2 ? 'visible' : ''}`}>
                        <button 
                            className="btn btn-primary" 
                            onClick={() => setShowFullscreenPrompts(!showFullscreenPrompts)}
                        >
                            Suggested Questions
                        </button>
                        {showFullscreenPrompts && (
                            <div 
                                className="prompts-list" 
                                style={{ 
                                    maxHeight: '400px', 
                                    overflowY: 'auto', 
                                    marginTop: '10px', 
                                    backgroundColor: 'white', 
                                    border: '1px solid #ccc', 
                                    padding: '10px'
                                }}
                            >
                                {prompts.map((prompt, index) => (
                                    <p key={index} style={{ margin: '5px 0' }}>
                                        {prompt}
                                    </p>
                                ))}
                            </div>
                        )}
                        <button className='fullscreen-button2' onClick={toggleHideChat2}>
                            {hideObject2 ? '□' : '-'}
                        </button>
                    </div>
                    <div className={`side-text-convo2 ${isFullscreen ? (hideObject ? 'hidden' : 'visible') : ''}`}>
                        <div>
                            <h2 className="card-title">Conversation</h2>
                            <button className='fullscreen-button2' onClick={toggleHideChat}>
                                {hideObject ? '□' : '-'}
                            </button>
                        </div>
                        <div className="card-body conversation-card-body">
                            {updatedConversation.map((msg, index) => (
                                <p key={index} style={{ color: 'black' }}>
                                    <strong>{msg.author}:</strong> {msg.content}
                                </p>
                            ))}
                            <div ref={messagesEndRef} />
                        </div>
                    </div>
                </div>
                
                <div className="prompt-box">
                    <button 
                        className="btn btn-primary" 
                        onClick={() => setShowPrompts(!showPrompts)}
                    >
                        Suggested Questions
                    </button>
                    {showPrompts && (
                        <div 
                            className="prompts-list" 
                            style={{ 
                                maxHeight: '400px', 
                                overflowY: 'auto', 
                                marginTop: '10px', 
                                backgroundColor: 'white', 
                                border: '1px solid #ccc', 
                                padding: '10px'
                            }}
                        >
                            {prompts.map((prompt, index) => (
                                <p key={index} style={{ margin: '5px 0' }}>
                                    {prompt}
                                </p>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div className='container-for-side'>
                <div className="side-text-convo">
                    <h2 className="card-title">Conversation</h2>
                    <div className="card-body conversation-card-body">
                        {updatedConversation.map((msg, index) => (
                            <p key={index} style={{ color: 'black' }}>
                                <strong>{msg.author}:</strong> {msg.content}
                            </p>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Visual;

import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

function AiConversationManager({selectedModel, speechToTextMessage , gotResponse, setGotResponse, conversation, setConversation, type , isPlaying,setEmotion }) {
    const [message, setMessage] = useState('');
     // State to store the conversation history
    useEffect(() => {
        // Code to run when selectedModel changes
       

        // For example, clear the conversation when the model changes
        setConversation([]);
  }, [selectedModel]);


  useEffect(() =>{
//if (type=="vertex"){
  //  console.log("running")
//sendMessage()
//} else{
    sendOtherMessage()
//}
  },[speechToTextMessage])

    async function sendMessage() {
        if(!speechToTextMessage || isPlaying){
          //  console.log("aborted")
            return
        }
        
        const functions = getFunctions();
        const predictFromVertexAI = httpsCallable(functions, 'predictFromVertexAI');

        

        // Update the conversation with the user's message
        let updatedConversation = [...conversation, { author: 'user', content: speechToTextMessage }];
       // console.log(updatedConversation)
        // Construct the prompt object with the updated conversation
        const prompt = {
            context: `your name is ${selectedModel.name}, ${selectedModel.description}`, // Context for the conversation
            messages: updatedConversation,
            tokens: 65
            
        };
        //if (selectedModel.examples[0] && selectedModel.examples.length > 0) {
       //     prompt.examples = selectedModel.examples;
       //   }
       // console.log(prompt)
       
        const temperature =   1.5//selectedModel.temperature;
       // console.log(temperature)
        predictFromVertexAI({ prompt, temperature})
            .then((result) => {
                const { predictions } = result.data;
                // Update the conversation with the AI's response
                updatedConversation = [...updatedConversation, { author: "ai", content: predictions[0] }];
                
                // Check if the conversation length exceeds 20 messages
              //  if (updatedConversation.length > 20) {
                    // Remove the first two messages to keep the conversation within limits
            //        updatedConversation = updatedConversation.slice(2);
            //    }
    
                setConversation(updatedConversation); // Update the conversation state
                setMessage(''); // Clear the message input
                setGotResponse(true)
            })
            .catch((error) => {
                console.error("Error calling the Cloud Function:", error.message);
            });
    }


async function     sendOtherMessage() {
    if(!speechToTextMessage  || isPlaying){
      //  console.log("aborted")
        return
    }
    const functions = getFunctions();
    const predictFromAI = httpsCallable(functions, 'predictFromAI');
    let updatedConversation = [...conversation, { role: 'user', content: speechToTextMessage }];

    const prompt = {
        context: `your name is ${selectedModel.name}, ${selectedModel.description},  Respond in a conversational manner with natural pauses, laughs, and expressions. Use phrases like "uh-huh", "hmm", "let me think", "haha", "oh really?", etc. . .If you see this is a system message, follow the order given. (15 WORDS MAXIMUM RESPONSE FOR EVERY RESPONSE)`, // Context for the conversation
        messages: updatedConversation,
        tokens: 40
    };
    if (selectedModel.examples && selectedModel.examples.length > 0) {
        prompt.examples = selectedModel.examples;
      }
    //  console.log(prompt)
      predictFromAI({ prompt})
      .then(async (result)  => {
        const emotions = httpsCallable(functions,"queryHuggingFace" )
      //  console.log("Result", result.data.predictions)
        const emote = await emotions({
            inputs: result.data.predictions,
            parameters: {}
          });
          setEmotion(emote.data[0].label)
          const { predictions } = result.data;
          // Update the conversation with the AI's response
          updatedConversation = [...updatedConversation, { role: "assistant", content: predictions }];
          
          // Check if the conversation length exceeds 20 messages
        //  if (updatedConversation.length > 20) {
              // Remove the first two messages to keep the conversation within limits
      //        updatedConversation = updatedConversation.slice(2);
      //    }

          setConversation(updatedConversation); // Update the conversation state
          setMessage(''); // Clear the message input
          setGotResponse(true)
      })
      .catch((error) => {
          console.error("Error calling the Cloud Function:", error.message);
      });


}

useEffect(() =>{


  if(gotResponse == false){
   
   
  }
},[conversation])

    return (
        <div>
          
          
        </div>
    );
}

export default AiConversationManager;

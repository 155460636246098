import React, { useEffect, useState } from 'react';

const CharacterCreatorBuilderAi = ({selectedVoice, setSelectedVoice, pitch, setPitch, speed, setSpeed}) => {


    const voiceOptions = [
        { label: "Felix", value: ["en-US", "en-US-Casual-K", "MALE"] },
        { label: "Luna", value: ["en-US", "en-US-Standard-C", "FEMALE"] },
        { label: "Quinn", value: ["en-US", "en-US-Neural2-A", "MALE"] },
        { label: "Sage", value: ["en-US", "en-US-Neural2-E", "FEMALE"] },
        { label: "Jasper", value: ["en-US", "en-US-Polyglot-1", "MALE"] },
        { label: "Nina", value: ["de-DE", "de-DE-Standard-B", "FEMALE"] }
    ];

    const handleSelectVoice = (voice) => {
        setSelectedVoice(voice);
    }

    const handleChangePitch = (event) => {
        setPitch(Number(event.target.value));
    }
    
    const handleChangeSpeed = (event) => {
        setSpeed(Number(event.target.value));
    }

    useEffect(() => {
        const root = document.documentElement;
        root.style.setProperty('--animation-duration', `${1 / ((speed/2)+0.5)}s`);
    }, [speed]);

    return (
        <div className='character-creator-voice-main-component'>
            <div className='character-creator-voice-base'>
                <label>Voice Base</label>
                <div className='character-creator-voice-base-container'>
                    <div className='character-creator-voice-base-select-container'>
                        {voiceOptions.map((voiceOption) => (
                            <button
                                className='character-creator-voice-base-options'
                                key={voiceOption.label}
                                onClick={() => handleSelectVoice(voiceOption)}
                            >
                                {voiceOption.label}
                            </button>
                        ))}
                    </div>

                    <div className='character-creator-voice-base-select-preview-container'>
                        <div className='character-creator-voice-base-select-preview'>
                        <audio controls>
        <source src="a" type="audio/mpeg"/>
        Your browser does not support the audio element.
    </audio>
                        </div>

                        <div className='character-creator-voice-base-select-preview-controls'>
                            <button className='character-creator-voice-btn'>Preview</button>
                            <div className='character-creator-voice-preview-base'>{selectedVoice.label}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='character-creator-voice-setting'>
                <label>Voice Settings</label>
                <div className='character-creator-voice-pitch'>
                   
                    <div className='character-creator-pitch-preview'>
                        {Array.from({ length: 11 }).map((_, index) => (
                            <div
                                key={index}
                                className='character-creator-pitch-waveform'
                                style={{ 
                                    height: `${ index%2==0 ? (pitch+3) * 2 : (pitch+6) * 2 }px`
                                }}
                            ></div>
                        ))}
                    </div>
                    <label>Pitch </label>
                    {(pitch)}
                    <input
                        type="range"
                        min="0"
                        max="20"
                        value={pitch}
                        onChange={handleChangePitch}
                        className="creator-slider"
                    />
                </div>

               
                <div className='character-creator-voice-pitch'>
                    <label>Speed </label>
                   
                    {(speed)}
                    <input
                        type="range"
                        min="0"
                        max="2"
                        step={0.01}
                        value={speed}
                        onChange={handleChangeSpeed}
                        className="creator-slider"
                    />
                </div>
            </div>

                       

            <div className='character-creator-voice-setting'>
            <label>Test Voice</label>

            <button className='btn btn-warning'>Test</button>
            

            </div>
        </div>
    );
}

export default CharacterCreatorBuilderAi;

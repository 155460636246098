import ManageVoice from "./ManageVoice";
import "./styles/Training.css"
// In Settings.js
function Training() {
    return <div className="training-container">
        <div className="Title">Voices</div>
        
        <ManageVoice/>
    </div>;
  }
  
  export default Training;
  
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, db } from './firebase-config'; // Import Firestore
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, updateProfile, sendEmailVerification, signOut, sendPasswordResetEmail, signInAnonymously } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore'; // Firestore methods
import CookieConsent from "react-cookie-consent"; // Cookie Consent
import "./styles/signIn.css";
import backgroundforest from "./styles/forest-background.png";

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState('');
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const guestLogin = queryParams.get('guest');

    if (guestLogin) {
      signInAnonymously(auth)
        .then(() => {
          navigate('/dashboard');
        })
        .catch((authError) => {
          console.error('Auth error:', authError.message);
          setError(authError.message);
        });
    }
  }, [location.search, navigate]);
  useEffect(() => {

    if (auth.currentUser && !auth.currentUser.emailVerified) {
      auth.currentUser.reload();
      
    }
  }, [])
  const handleAuth = (e) => {
    e.preventDefault();
    const action = isSigningUp
      ? createUserWithEmailAndPassword
      : signInWithEmailAndPassword;
  
    setError('');
    setSuccessMessage('');
  
    if (isSigningUp) {
      if (!username) {
        setError('Username is required.');
        return;
      }
      if (password.length < 7 || !specialCharRegex.test(password)) {
        setError(
          'Password must be at least 7 characters long and contain at least one special character.'
        );
        return;
      }
      if (password !== confirmPassword) {
        setError('Passwords do not match.');
        return;
      }
      if (!acceptTerms) {
        setError('You must accept the terms and privacy policy.');
        return;
      }
    }
  
 

    action(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
  
        if (isSigningUp) {
          await updateProfile(user, { displayName: username });
          await sendEmailVerification(user);
  
          // Save user info to Firestore
          await setDoc(doc(db, 'userInfo', user.uid), {
            username: username,
            email: user.email,
          });
  
          navigate('/send-email-verification');
        } else {
          // **Reload user data to get updated emailVerified status**
          await user.reload();
  
          // Re-fetch the user data
          const refreshedUser = auth.currentUser;
  
          if (refreshedUser.emailVerified) {
            navigate('/dashboard');
          } else {
            navigate('/send-email-verification');
          }
        }
      })
      .catch((authError) => {
        if (authError.code === 'auth/email-already-in-use') {
          setError('An account with this email already exists.');
        } else if (
          authError.code === 'auth/invalid-credential' ||
          authError.code === 'auth/wrong-password'
        ) {
          setError('Email or password is incorrect.');
        } else if (authError.code === 'auth/user-not-found') {
          setError('No account found with this email.');
        } else {
          console.error('Auth error:', authError.message);
          setError(authError.message);
        }
      });
  };
  

  const handleGuestLogin = (e) => {
    e.preventDefault();
    signInAnonymously(auth)
      .then((userCredential) => {
        navigate('/dashboard');
      })
      .catch((error) => {
        console.error('Guest auth error:', error.message);
        setError(error.message);
      });
  };

  const toggleAuthMode = () => {
    setIsSigningUp(!isSigningUp);
    setError('');
    setSuccessMessage('');
  };

  const handleForgotPassword = () => {
    if (!email) {
      setError('Please enter your email to reset password.');
      return;
    }

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSuccessMessage('Password reset email sent! Check your inbox.');
        setError('');
      })
      .catch((error) => {
        console.error('Error sending password reset email:', error);
        setError(error.message);
      });
  };

  const backgroundStyle = {
    backgroundImage: `url(${backgroundforest})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  return (
    <>
      {/* Cookie Consent */}
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="chaoticAppCookies"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
        <a href="/privacy-policy" style={{ color: "#f7d962" }}>
          Learn more
        </a>
      </CookieConsent>

      <div className="signin-wrapper signin-fadeInDown" style={backgroundStyle}>
        <div id="signin-formContent">
          <h2 className={!isSigningUp ? "signin-h2 active" : "signin-h2 inactive underlineHover"} onClick={() => setIsSigningUp(false)}> Sign In </h2>
          <h2 className={isSigningUp ? "signin-h2 active" : "signin-h2 inactive underlineHover"} onClick={() => setIsSigningUp(true)}>Sign Up </h2>

          <form onSubmit={handleAuth}>
            {isSigningUp && (
              <input type="text" id="username" className="signin-input signin-fadeIn second" name="username" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
            )}
            <input type="text" id="login" className="signin-input signin-fadeIn second" name="login" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <input type="password" id="password" className="signin-input signin-fadeIn third" name="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
            {isSigningUp && (
              <input type="password" id="confirmPassword" className="signin-input signin-fadeIn third" name="confirmPassword" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
            )}

            {isSigningUp && (
              <div>
                <input
                  type="checkbox"
                  id="acceptTerms"
                  checked={acceptTerms}
                  onChange={(e) => setAcceptTerms(e.target.checked)}
                />
                <label htmlFor="acceptTerms">
                  I agree to the{" "}
                  
                  <a href="/privacy-policy" target="_blank">Privacy Policy</a>.
                </label>
              </div>
            )}

            <div className="signin-buttons">
              <button type="button" className="signin-button signin-fadeIn fourth btn" onClick={handleGuestLogin}>
                Guest Log In
              </button>
              <button type="submit" className="signin-button signin-fadeIn fourth btn">
                {isSigningUp ? 'Sign Up' : 'Sign In'}
              </button>
            </div>
          </form>

          {!isSigningUp && (
            <div>
              <button onClick={handleForgotPassword} className="signin-forgot-btn signin-fadeIn fifth btn">
                Forgot Password?
              </button>
            </div>
          )}

          {error && <div className="signin-error-message">{error}</div>}
          {successMessage && <div className="signin-success-message">{successMessage}</div>}
        </div>
      </div>
    </>
  );
}

export default SignIn;

import React, { useState } from 'react';
import "../styles/CharacterCreator.css";

const CharacterCreatorFriends = ({friends, setFriends}) => {

  const [friendName, setFriendName] = useState('');
  const [friendType, setFriendType] = useState('');
  const [friendDescription, setFriendDescription] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [editingFriendIndex, setEditingFriendIndex] = useState(null); // Track the index of the friend being edited

  // Array of friend types for the dropdown
  const friendTypes = ['Friend', 'Enemy', 'Acquaintance', 'Family', 'Colleague', 'Other'];

  // Function to handle removing a friend
  const handleRemoveFriend = (index) => {
    // If the friend being removed is the one being edited, reset editing state
    if (editingFriendIndex === index) {
      setEditingFriendIndex(null);
      resetFormFields();
    }
    setFriends(friends.filter((_, i) => i !== index));
  };

  // Function to handle adding or editing a friend
  const handleAddOrEditFriend = () => {
    // Check if all fields are filled
    if (!friendName || !friendType || !friendDescription) {
      setErrorMessage('Please fill in all fields.');
      return;
    }

    // Clear any previous error message
    setErrorMessage('');

    if (editingFriendIndex !== null) {
      // Editing existing friend
      const updatedFriends = [...friends];
      updatedFriends[editingFriendIndex] = {
        friendName,
        friendType,
        friendDescription,
      };
      setFriends(updatedFriends);
      setEditingFriendIndex(null);
    } else {
      // Limit the amount of friends to 10
      if (friends.length >= 10) {
        setErrorMessage('You can only add up to 10 people.');
        return;
      }
      // Adding new friend
      setFriends([...friends, { friendName, friendType, friendDescription }]);
    }

    // Reset the form fields
    resetFormFields();
  };

  // Function to handle clicking on a friend to edit
  const handleEditFriend = (index) => {
    const friend = friends[index];
    setFriendName(friend.friendName);
    setFriendType(friend.friendType);
    setFriendDescription(friend.friendDescription);
    setEditingFriendIndex(index);
    setErrorMessage('')
  };

  // Function to reset form fields
  const resetFormFields = () => {
    setFriendName('');
    setFriendType('');
    setFriendDescription('');
    setErrorMessage('')
  };

  return (
    <div className='grid-friends-container'>
      <div className='friends-left'>
        <label>Added Friends</label>
        <div className='friend-card-container'>
          {/* Add Friend Button at the top */}
          <button
            onClick={() => {
              resetFormFields();
              setEditingFriendIndex(null);
            }}
            style={{
              width: '100%',
              backgroundColor: editingFriendIndex === null ? '#ff4740' : '#f7d962',
              borderBottom: '2px dashed black',
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
            }}
          >
            Add Friend
          </button>
          {
            friends.map((friend, index) => (
              <div
                key={index}
                className={`friend-card ${editingFriendIndex === index ? 'selected-friend' : ''}`}
                onClick={() => handleEditFriend(index)}
                style={{ cursor: 'pointer',  backgroundColor: editingFriendIndex === index ? '#ff4740' : '#f7d962',}}
              >
                <p className='friend-name-display'>{friend.friendName}</p>
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering edit mode when clicking remove
                    handleRemoveFriend(index);
                  }}
                  className='unfriend-button'
                  style={{
                    marginBottom: '10px',
                    width: '80px',
                    height: '30px',
                    fontSize: '1rem',
                    borderRadius: '5px',
                    border: '2px solid black',
                    backgroundColor: '#ff4740',
                  }}
                >
                  unfriend
                </button>
              </div>
            ))
          }
        </div>
      </div>

      <div className='friends-right'>
        <label>Relationship Details</label>
        <div className='friends-details-box'>
          <label>Name</label>
          <input
            value={friendName}
            onChange={(e) => setFriendName(e.target.value)}
          />

          <label>Type</label>
          <select
            value={friendType}
            onChange={(e) => setFriendType(e.target.value)}
          >
            <option value="">Select Type</option>
            {friendTypes.map((type, index) => (
              <option key={index} value={type}>{type}</option>
            ))}
          </select>

          <label>Relationship Description</label>
          <textarea
            value={friendDescription}
            onChange={(e) => setFriendDescription(e.target.value)}
            style={{ width: '90%', resize: 'none', height: '100px' }}
          />

          {/* Display error message if any */}
          {errorMessage && (
            <p className="error-message" style={{ color: 'red' }}>{errorMessage}</p>
          )}

          <button
            onClick={handleAddOrEditFriend}
            className='custom-attribute-button'
            style={{
              maxWidth: '150px',
              backgroundColor: editingFriendIndex !== null ? '#ff4740' : undefined,
            }}
          >
            {editingFriendIndex !== null ? 'Edit Person' : 'Add Person'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CharacterCreatorFriends;

import React, { useEffect } from 'react';

const StreamTwitchIntegration = ({
  username, setUsername,
  isLoggedIn, setIsLoggedIn,
  userId, setUserId,
  isStreaming, setIsStreaming,
  token, setToken,
  rewardIds, setRewardIds,
  setMessageQueue,
  customRewards, setCustomRewards // Add customRewards and setCustomRewards
}) => {

  useEffect(() => {
    const storedToken = localStorage.getItem('twitch_token');
    if (storedToken) {
      setToken(storedToken);
      fetchUserDetails(storedToken);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      validateToken();
    }
  }, [isLoggedIn, token]);

  const fetchUserDetails = async (accessToken) => {
    try {
      const response = await fetch(`https://api.twitch.tv/helix/users`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Client-ID': 'c0i4yt83s4fmr4d76renpisjqnd8cy'
        }
      });
      const data = await response.json();
      if (data.data.length > 0) {
        const user = data.data[0];
        setUsername(user.display_name);
        setUserId(user.id);
        setIsLoggedIn(true);
        checkStreamingStatus(accessToken, user.id);
        fetchCustomRewards(accessToken, user.id); 
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const validateToken = async () => {
    try {
      const response = await fetch(`https://id.twitch.tv/oauth2/validate`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (data.scopes.includes("channel:manage:redemptions")) {
        return true;
      } else {
        console.error('Invalid scopes.');
        return false;
      }
    } catch (error) {
      console.error('Error validating token:', error);
      return false;
    }
  };

  const checkStreamingStatus = async (accessToken, userId) => {
    try {
      const response = await fetch(`https://api.twitch.tv/helix/streams?user_id=${userId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Client-ID': 'c0i4yt83s4fmr4d76renpisjqnd8cy'
        }
      });
      const data = await response.json();
      setIsStreaming(data.data.length > 0);
    } catch (error) {
      console.error('Error checking streaming status:', error);
    }
  };

  const fetchCustomRewards = async (accessToken, userId) => {
    try {
      const response = await fetch(`https://api.twitch.tv/helix/channel_points/custom_rewards?broadcaster_id=${userId}&only_manageable_rewards=true`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Client-ID': 'c0i4yt83s4fmr4d76renpisjqnd8cy'
        }
      });
      const data = await response.json();
      if (data.data && data.data.length > 0) {
        setRewardIds(data.data.map(reward => reward.id));
        setCustomRewards(data.data); // Store the custom rewards
        pollForRedemptions(accessToken, userId, data.data.map(reward => reward.id));
      }
    } catch (error) {
      console.error('Error fetching custom rewards:', error);
    }
  };

  const fulfillRedemption = async (accessToken, userId, redemptionId, rewardId) => {
    try {
      const url = `https://api.twitch.tv/helix/channel_points/custom_rewards/redemptions?broadcaster_id=${userId}&reward_id=${rewardId}&id=${redemptionId}`;
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Client-ID': 'c0i4yt83s4fmr4d76renpisjqnd8cy',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ status: 'FULFILLED' })
      });
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error fulfilling redemption:', response.status, response.statusText, errorData);
      } else {
       // console.log(`Redemption ${redemptionId} fulfilled.`);
      }
    } catch (error) {
      console.error('Error fulfilling redemption:', error);
    }
  };

  const pollForRedemptions = async (accessToken, userId, rewardIds) => {
    const poll = async () => {
      for (const rewardId of rewardIds) {
        if (!rewardId) {
          console.warn('Reward ID is not set. Skipping redemption poll.');
          continue;
        }

        try {
          const response = await fetch(`https://api.twitch.tv/helix/channel_points/custom_rewards/redemptions?broadcaster_id=${userId}&reward_id=${rewardId}&status=UNFULFILLED`, {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Client-ID': 'c0i4yt83s4fmr4d76renpisjqnd8cy'
            }
          });
          const data = await response.json();

          if (data.data) {
            const newRedemptions = data.data.map(redemption => ({
              ...redemption,
              redemptionId: redemption.id,
              rewardId: rewardId
            }));

            // Add new redemptions to the queue
            setMessageQueue(prevQueue => {
              const existingMessages = prevQueue.map(msg => msg.trim());
              const newMessages = newRedemptions.map(redemption => {
                return redemption.user_input ? `${redemption.user_name} said: ${redemption.user_input}` : `${redemption.user_name} said: ${redemption.reward.prompt}`;
              });
              const uniqueNewMessages = newMessages.filter(msg => !existingMessages.includes(msg.trim()));
              return [...prevQueue, ...uniqueNewMessages];
            });

            for (const redemption of newRedemptions) {
              await fulfillRedemption(accessToken, userId, redemption.id, rewardId);
            }
          }
        } catch (error) {
          console.error('Error fetching redemptions:', error);
        }
      }

      // Poll again after a delay
      setTimeout(poll, 5000);
    };

    poll(); // Start the polling loop
  };

  return (
    <></>
  );
};

export default StreamTwitchIntegration;
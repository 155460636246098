import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase-config';
import "./styles/CharacterCreator.css"; // Import your CSS styles

const CharacterCreator = ({ hideSideNav, setHideSideNav }) => {
    const navigate = useNavigate();
    const [isAnonymous, setIsAnonymous] = useState(false);

    useEffect(() => {
        setHideSideNav(false);

        // Listen for authentication state changes
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setIsAnonymous(user.isAnonymous);
            } else {
                setIsAnonymous(false);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [setHideSideNav]);

    const handleAdvancedCreator = () => {
        navigate('/character-builder/advanced');
    };

    const handleSignIn = () => {
        // Log the user out to allow them to sign in properly
        auth.signOut()
            .then(() => {
                navigate('/signin'); // Redirect to the login page
            })
            .catch((error) => {
                console.error("Error signing out: ", error);
            });
    };

    return (
        <div className='main-container-for-character-creator'>
            {isAnonymous ? (
                <div
                    style={{
                        position: 'relative',
                        width: '100%',
                        minHeight: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                    }}
                >
                    <p style={{ fontSize: '1.5em', marginBottom: '20px' }}>
                        Please sign in to create characters
                    </p>
                    <button
                        onClick={handleSignIn}
                        style={{
                            padding: '15px 30px',
                            backgroundColor: '#ff4740',
                            color: 'white',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '1.2em',
                            borderRadius: '5px',
                        }}
                    >
                        Sign In
                    </button>
                </div>
            ) : (
                <>
                    <div className="character-creator-title">
                        <h1>Character Creator</h1>
                    </div>
                    <div className="character-creator-container-preview">
                        <div className='creator-create-box advanced-box' onClick={handleAdvancedCreator}>
                            <h2>Create</h2>
                            <p>Take full control of your character's design with our advanced options. Customize every detail to create a unique AI character tailored to your needs.</p>
                            <p className='recommended'>Recommended for streamers, content creators, and detailed customizations.</p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default CharacterCreator;

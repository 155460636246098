import React, { useEffect, useRef, useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

const StreamTextToSpeech = ({ message, selectedCharacter, setIsPlaying }) => {
  const [audioSrc, setAudioSrc] = useState('');
  const audioRef = useRef(new Audio());
  const [prevMessageContent, setPrevMessageContent] = useState('');

  useEffect(() => {
    if (message && message.content && message.content !== prevMessageContent) {
      handleTextSubmit();
      setPrevMessageContent(message.content);
    }
  }, [message, prevMessageContent]);

  const handleTextSubmit = async () => {
    try {
      const functions = getFunctions();
      const textToSpeech = httpsCallable(functions, 'textToSpeech');
      //console.log("Turned Text to Speech");
      const result = await textToSpeech({
        text: message.content,
        voice: { 
          name: selectedCharacter.voice[1], 
          languageCode: selectedCharacter.voice[0], 
          ssmlGender: selectedCharacter.voice[2] 
        },
        audioConfig: { 
          audioEncoding: 'MP3', 
          pitch: selectedCharacter.pitch, 
          speakingRate: selectedCharacter.speed 
        }
      });

      const audioContent = `data:audio/mp3;base64,${result.data.audioContent}`;
      setAudioSrc(audioContent);
    } catch (error) {
      console.error('Error fetching audio:', error);
    }
  };

  useEffect(() => {
    if (audioSrc) {
      audioRef.current.src = audioSrc;
      audioRef.current.play().catch((error) => {
        console.error('Error playing audio:', error);
      });
    }
  }, [audioSrc]);

  return (
    <audio
      ref={audioRef}
      controls
      autoPlay
      style={{ visibility: 'hidden' }}
      onPlay={() => setIsPlaying(true)}
      onPause={() => setIsPlaying(false)}
      onEnded={() => setIsPlaying(false)}
    >
      Your browser does not support the audio element.
    </audio>
  );
};

export default StreamTextToSpeech;

import React, { useState, useEffect } from 'react';
import { db, storage } from './firebase-config';
import { collection, addDoc, query, where, getDocs, doc, deleteDoc, getDoc, updateDoc, or } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { auth } from './firebase-config';
import { v4 as uuidv4 } from 'uuid';
import imageCompression from 'browser-image-compression';
import "./styles/Training.css";
import { Tooltip } from 'react-tooltip';
function ManageModels() {
  const [modelName, setModelName] = useState('');
  const [description, setDescription] = useState('');
  const [models, setModels] = useState([]);
  const [voices, setVoices] = useState([]);
  const modelsCollectionRef = collection(db, "characters");
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [temperature, setTemperature] = useState(1);
  const [type, setType] = useState('3D');
  const [selectedEmotion, setSelectedEmotion] = useState(null);
  const [images, setImages] = useState({});
  const [fileNames, setFileNames] = useState({});
  const [mainImage, setMainImage] = useState(null);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [loading, setLoading] = useState(false);
  const [totalImageSize, setTotalImageSize] = useState(0);
  const [validationErrors, setValidationErrors] = useState({});
  const [modelToDelete, setModelToDelete] = useState(null);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [editingModel, setEditingModel] = useState(null);
  const [editModelName, setEditModelName] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [editVoice, setEditVoice] = useState('');
  const [editPitch, setEditPitch] = useState(0);
  const [editSpeed, setEditSpeed] = useState(0);
  const [editRandomness, setEditRandomness] = useState(0)



  const voicesTable = [
    { label: "Felix", value: ["en-US", "en-US-Casual-K", "MALE"] },
    { label: "Luna", value: ["en-US", "en-US-Standard-C", "FEMALE"] },
    { label: "Quinn", value: ["en-US", "en-US-Neural2-A", "MALE"] },
    { label: "Sage", value: ["en-US", "en-US-Neural2-E", "FEMALE"] },
    { label: "Jasper", value: ["en-US", "en-US-Polyglot-1", "MALE"] },
    { label: "Nina", value: ["de-DE", "de-DE-Standard-B", "FEMALE"] },
    { label: "Great Bob", value: ["en-US", "en-US-Wavenet-C", "MALE"] }
  ];

  const emotionOptions = [
    'Admiration', 'Anger', 'Confusion', 'Curiosity',
    'Fear', 'Love', 'Nervousness', 'Pride', 'Realization',
    'Joy', 'Sadness', 'Neutral'
  ];

  const validateInput = (value, min, max) => {
    if (value < min) return min;
    if (value > max) return max;
    return value;
  };

  const handlePitchChange = (e) => {
    const value = Number(e.target.value);
    const validValue = validateInput(value, 1, 10);
    setEditPitch(validValue);
  };

  // Handler for speed input validation
  const handleSpeedChange = (e) => {
    const value = Number(e.target.value);
    const validValue = validateInput(value, 1, 10);
    setEditSpeed(validValue);
  };

  // Handler for randomness input validation
  const handleRandomnessChange = (e) => {
    const value = Number(e.target.value);
    const validValue = validateInput(value, 1, 10);
    setEditRandomness(validValue);
  };

  const createModel = async () => {
    const errors = {};
    if (!modelName) errors.modelName = true;
    if (!description) errors.description = true;
    if (!selectedVoice) errors.selectedVoice = true;
    if (Object.keys(images).length === 0) errors.images = true;

    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      setLoading(false);
      return;
    }

    if (!auth.currentUser) return;
    setLoading(true);
    const modelID = uuidv4();

    const imageUrls = {};

    for (const [emotion, imageData] of Object.entries(images)) {
      const imageRef = ref(storage, `models/${modelID}/${emotion}`);
      const response = await fetch(imageData);
      const blob = await response.blob();
      await uploadBytes(imageRef, blob);
      const downloadURL = await getDownloadURL(imageRef);
      imageUrls[emotion] = downloadURL;
    }

    const payload = {
      name: modelName,
      description: description,
      voice: selectedVoice.voiceSet,
      voiceName: selectedVoice.name,
      voicePitch: selectedVoice.pitch,
      voiceSpeed: selectedVoice.speed,
      userId: auth.currentUser.uid,
      temperature: temperature,
      type: type,
      emotions: imageUrls,
      mainImage: mainImage,
      modelID: modelID,
    };

    await addDoc(modelsCollectionRef, payload);
    resetForm();
    fetchModels();
    setLoading(false);
    setValidationErrors({});
    closeModal();
  };

  const fetchModels = async () => {
    if (!auth.currentUser) return;
    
    const q = query(
      modelsCollectionRef,
      or(
        where("userId", "==", auth.currentUser.uid),
        where("all", "==", true)
      )
    );
    const querySnapshot = await getDocs(q);
    const modelsArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setModels(modelsArray);
  };

 

  const editingOver = () => {
    setEditingModel(null);
    resetForm();
  };

  

  useEffect(() => {
    fetchModels();
  }, [auth.currentUser]);

  const deleteModel = async (id) => {
    const modelDoc = doc(db, "characters", id);
    const model = (await getDoc(modelDoc)).data();
  
    if (model && model.images) {
      for (const [emotion, imageVariants] of Object.entries(model.images)) {
        // Loop through each variant (e.g., default, talking, blinking)
        for (const [variant, imageUrl] of Object.entries(imageVariants)) {
          const imageRef = ref(storage, imageUrl);
          await deleteObject(imageRef).catch((error) => {
            console.error(`Failed to delete ${variant} image for ${emotion}: ${error.message}`);
          });
        }
      }
    }
  
    // Delete the Firestore document after images are deleted
    await deleteDoc(modelDoc);
    fetchModels();
    setShowConfirmDeleteModal(false);
  };
  

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file && selectedEmotion) {
      const options = {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 720,
        useWebWorker: true,
        maxIteration: 5
      };

      try {
        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onloadend = () => {
          const imageSize = compressedFile.size;
          setImages(prevState => ({ ...prevState, [selectedEmotion]: reader.result }));
          setFileNames(prevState => ({ ...prevState, [selectedEmotion]: compressedFile.name }));
          setTotalImageSize(prevSize => prevSize + imageSize);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Error compressing the image:", error);
      }
    }
  };

  const handleEmotionChange = (emotion) => {
    setSelectedEmotion(emotion);
    setFileInputKey(Date.now());
  };

  const removeImage = (emotion) => {
    if (images[emotion]) {
      const imageSize = new Blob([images[emotion]]).size;
      setImages(prevState => {
        const updatedImages = { ...prevState };
        delete updatedImages[emotion];
        return updatedImages;
      });
      setFileNames(prevState => {
        const updatedFileNames = { ...prevState };
        delete updatedFileNames[emotion];
        return updatedFileNames;
      });
      setTotalImageSize(prevSize => prevSize - imageSize);
    }
  };

  const closeModal = () => {
    const modal = document.getElementById('exampleModal');
    const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
    if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
    }
    if (modalBackdrop) {
      modalBackdrop.parentNode.removeChild(modalBackdrop);
    }
    document.body.classList.remove('modal-open');
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';
  };

  const confirmDeleteModel = (model) => {
    setModelToDelete(model);
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    deleteModel(modelToDelete.id);
  };

  const handleEditModel = (model) => {
    setEditingModel(model);
    setEditModelName(model.name);
    setEditDescription(model.description);
    setEditVoice(model.voice);
    setEditPitch(model.pitch);
    setEditSpeed(model.speed)
    setEditRandomness(model.randomness)
  };

  const updateModel = async () => {
    if (!editingModel) return;

    const modelDoc = doc(db, "characters", editingModel.id);
    await updateDoc(modelDoc, {
      name: editModelName,
      description: editDescription,
      voice: editVoice,
      pitch: editPitch,
      speed:editSpeed,
      randomness: editRandomness
    });

    fetchModels();
    editingOver();
  };

  const resetForm = () => {
    setModelName('');
    setDescription('');
    setSelectedVoice(null);
    setTemperature(1);
    setType('3D');
    setImages({});
    setFileNames({});
    setMainImage(null);
    setFileInputKey(Date.now());
    setTotalImageSize(0);
  };

  return (
    <div className='main-for-page'>
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}

      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">Create Model</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="model-name" className={`col-form-label ${validationErrors.modelName ? 'text-danger' : ''}`}>Name</label>
                  <input
                    className={`form-control ${validationErrors.modelName ? 'is-invalid' : ''}`}
                    id="model-name"
                    type="text"
                    placeholder="Model Name"
                    value={modelName}
                    onChange={(e) => setModelName(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="model-description" className={`col-form-label ${validationErrors.description ? 'text-danger' : ''}`}>Description:</label>
                  <textarea
                    className={`form-control ${validationErrors.description ? 'is-invalid' : ''}`}
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <label htmlFor="rate-pitch" className="col-form-label">Randomness:</label>
                <div className="slidecontainer">
                  <input
                    type="range"
                    min="0"
                    max="2"
                    step="0.1"
                    value={temperature}
                    onChange={(e) => setTemperature(e.target.value)}
                    className="slider"
                    id="myRange"
                  />
                  <p>Value: {temperature}</p>
                </div>

                <div className="mb-3">
                  <label htmlFor="voice-model" className={`col-form-label ${validationErrors.selectedVoice ? 'text-danger' : ''}`}>Voice Model:</label>
                  <div className="dropdown">
                    <button className={`btn btn-warning dropdown-toggle ${validationErrors.selectedVoice ? 'is-invalid' : ''}`} type="button" id="voiceDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                      {selectedVoice ? `${selectedVoice.name}` : "Select a voice"}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="voiceDropdown">
                      {voices.map((voice) => (
                        <li key={voice.id}>
                          <button className="dropdown-item" type="button" onClick={() => setSelectedVoice(voice)}>
                            {voice.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="visual-type" className="col-form-label">Visual Type:</label>
                  <div className="slidecontainer">
                    <input
                      type="range"
                      min="0"
                      max="1"
                      step="1"
                      value={type === '3D' ? 0 : 1}
                      onChange={(e) => setType(e.target.value === '0' ? '3D' : 'Photo')}
                      className="slider"
                      id="visualTypeRange"
                    />
                    <p>Type: {type}</p>
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="emotion" className="col-form-label">Emotion:</label>
                  <div className="dropdown">
                    <button className="btn btn-warning dropdown-toggle" type="button" id="emotionDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                      {selectedEmotion ? `${selectedEmotion}` : "Select an emotion"}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="emotionDropdown">
                      {emotionOptions.map((emotion) => (
                        <li key={emotion}>
                          <button className="dropdown-item" type="button" onClick={() => handleEmotionChange(emotion)}>
                            {emotion}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="image-size" className={`col-form-label ${validationErrors.images ? 'text-danger' : ''}`}>Images Size:</label>
                  <div className="progress">
                    <div
                      className={`progress-bar ${totalImageSize > 10485760 ? 'bg-danger' : 'bg-success'}`}
                      role="progressbar"
                      style={{ width: `${(totalImageSize / 10485760) * 100}%` }}
                      aria-valuenow={(totalImageSize / 10485760) * 100}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {Math.round(totalImageSize / 1024)} KB / 10 MB
                    </div>
                  </div>
                </div>

                {selectedEmotion && (
                  <div className="mb-3">
                    <label htmlFor="upload-image" className="col-form-label">Upload Image for {selectedEmotion}:</label>
                    <input
                      key={fileInputKey}
                      type="file"
                      className="form-control"
                      id="upload-image"
                      onChange={handleImageUpload}
                    />
                    {fileNames[selectedEmotion] && (
                      <div className="image-preview">
                        <p>Selected file: {fileNames[selectedEmotion]}</p>
                        <button type="button" className="btn btn-danger btn-sm" onClick={() => removeImage(selectedEmotion)}>
                          Remove Image
                        </button>
                      </div>
                    )}
                    {type === 'Photo' && images[selectedEmotion] && (
                      <div className="image-preview">
                        <img src={images[selectedEmotion]} alt={`${selectedEmotion} preview`} style={{ maxWidth: '100%', marginTop: '10px' }} />
                      </div>
                    )}
                  </div>
                )}

                {Object.keys(images).length > 0 && (
                  <div className="mb-3">
                    <label htmlFor="main-image" className="col-form-label">Main Image:</label>
                    <div className="dropdown">
                      <button className="btn btn-warning dropdown-toggle" type="button" id="mainImageDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                        {mainImage ? `${mainImage}` : "Select main image"}
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="mainImageDropdown">
                        {Object.keys(images).map((emotion) => (
                          <li key={emotion}>
                            <button className="dropdown-item" type="button" onClick={() => setMainImage(emotion)}>
                              {emotion}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}

              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button
                type="button"
                className="btn btn-warning"
                onClick={createModel}
                disabled={totalImageSize > 10485760}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='display-models'>
        {models.map((model) => (
          <div key={model.id} className='model-card'>
            <div className="card" style={{ width: '18rem' }}>
              <div className="card-body">
                <h5 className="card-title title-name">{model.name}</h5>
                <p className="card-text text-description">{model.description}</p>
                <p className="card-text">Randomness: {model.randomness}</p>
                {model.images.happy?.default && (
                  <div className="main-image-preview">
                    <img src={model.images.happy.default} alt={`${model.mainImage} preview`} className="fixed-size-image" />
                  </div>
                )}
                <ul className="list-group list-group-flush voice-card">
                  <li className="list-group-item voice-list-item">
                    Voice: {voicesTable.find(voice => voice.value[1] === model.voice[1])?.label || model.voice[1]}
                  </li>
                </ul>
                { model?.all === true ?(<div></div>):(
  <button className='btn btn-danger' onClick={() => confirmDeleteModel(model)}>Delete</button>

  )

}

{ model?.all === true ? (<div></div>): (

<button className='btn btn-warning' onClick={() => handleEditModel(model)} style={{ marginLeft: "5px" }}>Edit</button>
)

}
              </div>
            </div>
          </div>
        ))}
      </div>

      {editingModel && (
        <div className="modal fade show" tabIndex="-1" style={{ display: "block" }} aria-labelledby="editModelModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="editModelModalLabel">Edit Model</h5>
                <button type="button" className="btn-close" onClick={() => setEditingModel(null)}></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="edit-model-name" className="col-form-label">Name:</label>
                    <input
                      className="form-control"
                      id="edit-model-name"
                      type="text"
                      placeholder="Model Name"
                      value={editModelName}
                      onChange={(e) => setEditModelName(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="edit-model-description" className="col-form-label">Description:</label>
                    <i  style={{marginLeft: '4px', fontSize: '1rem'}}className="fa-regular fa-circle-question" data-tooltip-id="tooltip" 
                        data-tooltip-content="determines your character's personality, their relationships, and their overall identity."></i> 
                        <Tooltip id="tooltip"  style={{ maxWidth: '400px', whiteSpace: 'normal', wordWrap: 'break-word', zIndex: '1000',fontSize: '1rem'  }}/>
                    <textarea
                      className="form-control"
                      placeholder="Description"
                      value={editDescription}
                      onChange={(e) => setEditDescription(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="edit-model-description" className="col-form-label">Randomness:</label>
                    <i  style={{marginLeft: '4px', fontSize: '1rem'}}className="fa-regular fa-circle-question" data-tooltip-id="tooltip" 
                        data-tooltip-content="Makes your character's responses more outlandish the higher the value. Raise it to 10 for MAXIMUM CHAOS"></i> 
                        <Tooltip id="tooltip"  style={{ maxWidth: '400px', whiteSpace: 'normal', wordWrap: 'break-word', zIndex: '1000',fontSize: '1rem'  }}/>
                    <input
  type="number"
  className="form-control"
  placeholder="Randomness"
  value={editRandomness}
  onChange={handleRandomnessChange}
  min="1"
  max="10"
/>

                  </div>
                  

                  
                  <div className="mb-3">
                    <label htmlFor="edit-model-voice" className="col-form-label">Voice:</label>
                    <i  style={{marginLeft: '4px', fontSize: '1rem'}}className="fa-regular fa-circle-question" data-tooltip-id="tooltip" 
                        data-tooltip-content="pick from a selection of curated voices"></i> 
                        <Tooltip id="tooltip"  style={{ maxWidth: '400px', whiteSpace: 'normal', wordWrap: 'break-word', zIndex: '1000',fontSize: '1rem'  }}/>
                    <select
                      className="form-control"
                      value={editVoice ? voicesTable.find(voice => voice.value[1] === editVoice[1])?.label : ""}
                      onChange={(e) => {
                        const selectedVoice = voicesTable.find(voice =>  voice.label === e.target.value);
                        setEditVoice(selectedVoice ? selectedVoice.value : []);
                      }}
                    >
                      <option value="" disabled>
                        {voicesTable.find(voice => voice.value[1] === editVoice[1])?.label || "Select a Voice"}
                      </option>
                      {voicesTable
        .filter(voice => voice.value[1] !== "en-US-Wavenet-C")
        .map((voice, index) => (
    <option key={index} value={voice.label}>
      {voice.label}
    </option>
  ))}

                    </select>
                  </div>

                  <div className="mb-3">
  <label htmlFor="edit-model-description" className="col-form-label">Pitch:</label>
  <i  style={{marginLeft: '4px', fontSize: '1rem'}}className="fa-regular fa-circle-question" data-tooltip-id="tooltip" 
                        data-tooltip-content="higher pitch offers squeaker voices, low pitch creates deeper tones instead"></i> 
                        <Tooltip id="tooltip"  style={{ maxWidth: '400px', whiteSpace: 'normal', wordWrap: 'break-word', zIndex: '1000',fontSize: '1rem'  }}/>
  <input
    type="number"
    className="form-control"
    placeholder="Pitch"
    value={editPitch}
    onChange={handlePitchChange}
    min="1"
    max="10"
  />
</div>


<div className="mb-3">
  <label htmlFor="edit-model-description" className="col-form-label">Speed:</label>
  <i  style={{marginLeft: '4px', fontSize: '1rem'}}className="fa-regular fa-circle-question" data-tooltip-id="tooltip" 
                        data-tooltip-content="Makes a character speak faster with higher values, in case you want a slow southern drawl or mumbling nonsense"></i> 
                        <Tooltip id="tooltip"  style={{ maxWidth: '400px', whiteSpace: 'normal', wordWrap: 'break-word', zIndex: '1000',fontSize: '1rem'  }}/>
  <input
    type="number"
    className="form-control"
    placeholder="Speed"
    value={editSpeed}
    onChange={handleSpeedChange}
    min="1"
    max="10"
  />
</div>


                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => editingOver()}>Close</button>
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={updateModel}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showConfirmDeleteModal && (
        <div className="modal fade show" tabIndex="-1" style={{ display: "block" }} aria-labelledby="confirmDeleteModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="confirmDeleteModalLabel">Confirm Deletion</h5>
                <button type="button" className="btn-close" onClick={() => setShowConfirmDeleteModal(false)}></button>
              </div>
              <div className="modal-body">
                Are you sure you want to delete the model "{modelToDelete?.name}"?
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowConfirmDeleteModal(false)}>Cancel</button>
                <button type="button" className="btn btn-danger" onClick={handleConfirmDelete}>Delete</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ManageModels;

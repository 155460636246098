import ManageModels from "./ManageModels";
import "./styles/Training.css"
import { Tooltip } from 'react-tooltip';
// In Settings.js
function Training() {
    return <div className="training-container">
        <div className="Title">Characters <i  style={{marginLeft: '4px', fontSize: '1.3rem'}}className="fa-regular fa-circle-question" data-tooltip-id="tooltip" 
                        data-tooltip-content="Here is where you can view the characters you've made and edit them to your hearts content! We've given you great bob to get the ball rolling!
"></i> 
                        <Tooltip id="tooltip"  style={{ maxWidth: '400px', whiteSpace: 'normal', wordWrap: 'break-word', zIndex: '10',fontSize: '1rem'  }}/></div>  
        
        <ManageModels/>
    </div>;
  }
  
  export default Training;
  
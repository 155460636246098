import React, { useState } from 'react';
import "../styles/CharacterCreator.css";
import { Tooltip } from 'react-tooltip';

const CharacterCreatorBuilderSideNav = ({
  isBlinking,
  setIsBlinking,
  isTalking,
  setIsTalking,
  selectedTab,
  setSelectedTab,
}) => {
  const handleBlinkingChange = () => {
    setIsBlinking((prevState) => !prevState);
  };

  const handleTalkingChange = () => {
    setIsTalking((prevState) => !prevState);
  };

  return (
    <div className='side-control-navigation-right'>
      <div className='switcher-create'>
        <button
          className={`switcher-button left-create-btn ${
            selectedTab === "visual" ? "active-tab-create" : ""
          }`}
          onClick={() => setSelectedTab("visual")}
        >
          Visual     <i
                style={{ marginLeft: '4px', fontSize: '1rem' }}
                className="fa-regular fa-circle-question"
                data-tooltip-id="tooltip-visual"
                data-tooltip-content="Here's the visual part of your character's creation! You can insert any art you have of your character and
                enable clicking and blinking to make your little fella behave more realistically and get visable angry, mad, sad, or happy, for a given emotion."
              ></i>
              <Tooltip
                id="tooltip-visual"
                style={{
                  maxWidth: '250px',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  zIndex: '10',
                  fontSize: '1rem',
                }}
              />
        </button>
        <button
          className={`switcher-button middle-create-btn ${
            selectedTab === "ai" ? "active-tab-create" : ""
          }`}
          onClick={() => setSelectedTab("ai")}
        >
          Description
          <i
                style={{ marginLeft: '4px', fontSize: '1rem' }}
                className="fa-regular fa-circle-question"
                data-tooltip-id="tooltip-description"
                data-tooltip-content="Here's the meat of your character's personality! You can write a brief descripiton of their idenity and personality as well as any info you want to know!"
              ></i>
              <Tooltip
                id="tooltip-description"
                style={{
                  maxWidth: '250px',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  zIndex: '10',
                  fontSize: '1rem',
                }}
              />
        </button>

        <button
          className={`switcher-button right-create-btn ${
            selectedTab === "voice" ? "active-tab-create" : ""
          }`}
          onClick={() => setSelectedTab("voice")}
        >
          Voice
          <i
                style={{ marginLeft: '4px', fontSize: '1rem' }}
                className="fa-regular fa-circle-question"
                data-tooltip-id="tooltip-voice"
                data-tooltip-content="Here's where you can pick the voice of your character. Though we intend to add some more curated voices and even throw Hatsune Miku into the mix, you pick any of the carefully selected voices and raise and lower their pitch"
              ></i>
              <Tooltip
                id="tooltip-voice"
                style={{
                  maxWidth: '200px',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  zIndex: '10',
                  fontSize: '1rem',
                }}
              />
        </button>
      </div>

      <div className='tab-content'>
        {selectedTab === "visual" && (
          <div className='side-nav-component-right-layout'>
            <div className='inner-component-builder-right'>
              Blinking
              <label className="switch-custom">
                <input
                  type="checkbox"
                  checked={isBlinking}
                  onChange={handleBlinkingChange}
                />
                <span className="slider round"></span>
              </label>
              {/* Updated Tooltip ID for Blinking */}
              <i
                style={{ marginLeft: '4px', fontSize: '1.3rem' }}
                className="fa-regular fa-circle-question"
                data-tooltip-id="tooltip-blinking"
                data-tooltip-content="makes your character blink in real time, requires one extra picture"
              ></i>
              <Tooltip
                id="tooltip-blinking"
                style={{
                  maxWidth: '200px',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  zIndex: '10',
                  fontSize: '1rem',
                }}
              />
            </div>

            <div className='inner-component-builder-right'>
              Talking
              <label className="switch-custom">
                <input
                  type="checkbox"
                  checked={isTalking}
                  onChange={handleTalkingChange}
                />
                <span className="slider round"></span>
              </label>
              {/* Updated Tooltip ID for Talking */}
              <i
                style={{ marginLeft: '4px', fontSize: '1.3rem' }}
                className="fa-regular fa-circle-question"
                data-tooltip-id="tooltip-talking"
                data-tooltip-content="Shows this face when your character talks."
              ></i>
              <Tooltip
                id="tooltip-talking"
                style={{
                  maxWidth: '200px',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  zIndex: '10',
                  fontSize: '1rem',
                }}
              />
            </div>
          </div>
        )}

        {selectedTab === "ai" && (
          <div>
          
          </div>
        )}

        {selectedTab === "voice" && (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default CharacterCreatorBuilderSideNav;

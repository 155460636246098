import { Dropdown } from 'bootstrap';
import { Tooltip } from 'react-tooltip';

import React, { useState } from 'react';
import CharacterCreatorBuilderAttributes from './CharacterCreatorBuilderAttributes';
import CharacterCreatorFriends from './CharacterCreatorFriends';

import angry_closed from '../styles/test-images/angry_eyesclosed_mouthclosed.png';
import angry_closed_talk from '../styles/test-images/angry_eyesclosed_mouthopen.png';
import angry_open from '../styles/test-images/angy_eyesopen_mouthclosed.png';
import angry_open_talk from '../styles/test-images/angy_eyesopen_mouthopen.png';

import confused_closed from '../styles/test-images/confused_eyesclosed_mouthclosed.png';
import confused_open from '../styles/test-images/confused_eyesopen_mouthclosed.png';
import confused_closed_talk from '../styles/test-images/confused_eyesclosed_mouthopen.png';
import confused_open_talk from '../styles/test-images/confused_eyesopen_mouthopen.png';

import happy_closed from '../styles/test-images/happy_eyesclosed_mouthclosed.png';
import happy_closed_talk from '../styles/test-images/happy_eyesclosed_mouthopen.png';
import happy_open from '../styles/test-images/happy_eyesopen_mouthclosed.png';
import happy_open_talk from '../styles/test-images/happy_eyesopen_mouthopen.png';

import sad_closed from '../styles/test-images/sad_eyesclosed_mouthclosed.png';
import sad_open from '../styles/test-images/sad_eyesopen_closedmouth.png';
import sad_closed_talk from '../styles/test-images/sad_eyesclosed_mouthopen.png';
import sad_open_talk from '../styles/test-images/sad_eyesopen_openmouth.png';

const CharacterCreatorBuilderAi = ({randomness, setRandomness, selectedAttributes, setSelectedAttributes, description, setDescription, name, setName, friends, setFriends}) => {
  const attributes = ["happy", "mean", "sassy", "scared", "anxious", "brave", "curious", "angry", "confused", "calm", "shy", "bored", "determined", "friendly", "sleepy", "enthusiastic", "optimistic"];
  
  const [selectedImage, setSelectedImage] = useState(happy_closed);

  const [customAttribute, setCustomAttribute] = useState('');
  const [isInputEmpty, setIsInputEmpty] = useState(false);


  const handleSelectAttribute = (attribute) => {
    if (!selectedAttributes.includes(attribute)) {
      setSelectedAttributes([...selectedAttributes, attribute]);
    }
  };

  const handleRemoveAttribute = (attribute) => {
    setSelectedAttributes(selectedAttributes.filter(attr => attr !== attribute));
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setRandomness(value);

    switch (value) {
      case '0':
        setSelectedImage(happy_closed);
        break;
      case '1':
        setSelectedImage(happy_closed_talk);
        break;
      case '2':
        setSelectedImage(happy_closed_talk);
        break;
      case '3':
        setSelectedImage(happy_open);
        break;
      case '4':
        setSelectedImage(happy_open_talk);
        break;
      case '5':
        setSelectedImage(happy_open_talk);
        break;
      case '6':
        setSelectedImage(confused_closed);
        break;
      case '7':
        setSelectedImage(confused_open);
        break;
      case '8':
        setSelectedImage(confused_open_talk);
        break;
      case '9':
        setSelectedImage(angry_open);
        break;
      case '10':
        setSelectedImage(angry_open_talk);
        break;
      default:
        setSelectedImage(happy_open_talk);
    }
  };

  const handleAddCustomAttribute = () => {
    if (customAttribute.trim() === '') {
      setIsInputEmpty(true);
    } else {
      setSelectedAttributes([...selectedAttributes, customAttribute]);
      setCustomAttribute('');
      setIsInputEmpty(false);
    }
  };

  return (
    <div className='character-creator-ai-tab'>
      <label>Companion Name</label>
      <input
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <div className='character-creator-ai-middle'>
        <div className='character-creator-ai-description-container'>
        <div>
  <label style={{marginRight: '4px'}}>Companion Description</label>
  <i className="fa-regular fa-circle-question" data-tooltip-id="tooltip" data-tooltip-content="Describe your character's personality"></i>
  <Tooltip id="tooltip" />
</div>

          <textarea
            placeholder='Description'
            className='character-creator-ai-description-box form-control'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className='character-creator-ai-right-section-container'>
          <div className='character-creator-ai-right-section-randomness-container'>
          <div>
            randomness
            <i  style={{marginLeft: '4px'}}className="fa-regular fa-circle-question" data-tooltip-id="tooltip" data-tooltip-content="How crazy your character gets"></i>
  <Tooltip id="tooltip" />
            </div>
            <img src={selectedImage} className='character-creator-ai-right-section-randomness-container-image' />
            <div className="slider-value">{randomness}</div>
            <div className="character-creator-ai-realative">
              <input
                type="range"
                min="0"
                max="10"
                value={randomness}
                onChange={handleChange}
                className="creator-slider"
              />
            </div>
          </div>

        
          <div className='character-creator-ai-right-section-attributes-container'>
        <div>
          attributes section
          <i  style={{marginLeft: '4px'}}className="fa-regular fa-circle-question" data-tooltip-id="tooltip" data-tooltip-content="Specific attributes about your character"></i>
          <Tooltip id="tooltip" />
          </div>
        <div className='character-creator-ai-right-section-attributes-container-inner'>
         
            <div className='character-creator-ai-right-section-attributes-box'>
              {selectedAttributes.map((attribute) => (
                <div key={attribute} className='character-creator-ai-right-section-attribute'>
                  <div className='character-creator-ai-right-section-attribute-text'>{attribute}</div>
                  <button className='btn-custum-creator' onClick={() => handleRemoveAttribute(attribute)}>
                    <i className="fa-solid fa-minus"></i>
                  </button>
                </div>
              ))}
            </div>

            <div className='character-creator-ai-right-section-attributes-select'>
              {attributes.map((attribute) => (
                <div key={attribute} className='character-creator-ai-right-section-attribute'>
                  <div className='character-creator-ai-right-section-attribute-text'>{attribute}</div>
                  <button className='btn-custum-creator' onClick={() => handleSelectAttribute(attribute)}>
                    <i className="fa-solid fa-plus"></i>
                  </button>
                </div>
              ))}
            </div>

               

            <div className='character-creator-ai-right-section-custom-attributes-box'>
              custom attribute
              
              <input
                className={`character-creator-ai-input-custom-attribute ${isInputEmpty ? 'input-error' : ''}`}
                value={customAttribute}
                onChange={(e) => setCustomAttribute(e.target.value)}
              />
              <button className='btn custom-attribute-button' onClick={handleAddCustomAttribute}>ADD</button>
            </div>
            
          </div>
        </div>
        <div className='attributres-wrapper-div'>
          <div>
          Attributes Advanced
          <i  style={{marginLeft: '4px'}}className="fa-regular fa-circle-question" data-tooltip-id="tooltip" data-tooltip-content="More specific attributes selection"></i>
          <Tooltip id="tooltip" />
          </div>
        <CharacterCreatorBuilderAttributes />
        </div>

        <div className='attributres-wrapper-div'> 
              Friends
              <CharacterCreatorFriends friends={friends} setFriends={setFriends}/>
        </div>
        </div>
      </div>

      <div className='character-creator-ai-bottom'>
        test area
        <label>input</label>
        <div className='character-creator-ai-testing-container-input'>
          <input className='character-creator-ai-input-text-test' />
        </div>
        <button>send</button>
        <label>output</label>
        <textarea className='character-creator-ai-output' />
      </div>
    </div>
  );
};

export default CharacterCreatorBuilderAi;

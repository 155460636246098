import React, { useState, useEffect, useRef} from 'react';
import { db } from './firebase-config';
import {  collection, addDoc, query, where, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { auth } from './firebase-config';
import { getFunctions, httpsCallable } from 'firebase/functions';
import "./styles/Training.css"
import "./styles/Voices.css"
function ManageVoice() {
  const [voiceName, setVoiceName] = useState('');
  const [description, setDescription] = useState('');
  const [voices, setVoices] = useState([]);
  const [voiceSet, setVoiceSet] = useState(["en-us","en-US-Casual-k","MALE"]);
  const [pitch, setPitch] = useState(10)
  const [speed, setSpeed] = useState(1)
  const voicesCollectionRef = collection(db, "Voices"); // Reference to the 'Voices' collection in Firestore
  const [audioSrc, setAudioSrc] = useState('');
  const [visualVoices, setVisualVoices] = useState('MALE')
  const formRef = useRef(null);
const audioSrcRef = useRef('')
  // Function to reset form and state
  const resetFormAndState = () => {
    setVoiceName('');
    setDescription('');
    setPitch(10);
    setSpeed(1);
    setVoiceSet(["en-us", "en-US-Casual-k", "MALE"]);
    setAudioSrc();
    if (formRef.current) {
      formRef.current.reset(); // Reset the form fields
    }
  };
  const voiceOptions = [
    { label: "en-US-Casual-K", value: ["en-US", "en-US-Casual-K", "MALE"] },

    { label: "en-US-Standard-C", value: ["en-US", "en-US-Standard-C", "FEMALE"] },
    { label: "en-US-Neural2-A", value: ["en-US", "en-US-Neural2-A", "MALE"] },
    { label: "en-US-Neural2-E", value: ["en-US", "en-US-Neural2-E", "FEMALE"] },
    { label: "en-US-Polyglot-1", value: ["en-US", "en-US-Polyglot-1", "MALE"] },
    { label: "de-DE-Standard-B", value: ["de-DE", "de-DE-Standard-B", "FEMALE"] }
  ];
  const handleCreateAndTestVoice = async () => {
    await createVoice(); // First, run createVoice
    testVoice();   // Then, run testVoice
  };
  // Function to add a new Voice to Firestore
  const createVoice = async () => {
    if (!auth.currentUser) return; // Ensure there's a logged-in user
    await addDoc(voicesCollectionRef, {
      name: voiceName,
      description: description,
      voiceSet: voiceSet,
      pitch: pitch,
      speed: speed,
      testVoice: audioSrcRef.current,
      userId: auth.currentUser.uid // Store the user ID with the Voice for later retrieval
    });
    setVoiceName('');
    setDescription('');
    fetchVoices();
    setSpeed(1)
    setPitch(10)  
    setAudioSrc('')
    setVoiceSet(["en-US-Casual-k","en-us","MALE"])              // Refresh the list of Voices after adding a new one
  };

  // Function to fetch all Voices created by the current user
  const fetchVoices = async () => {
    if (!auth.currentUser) return; // Ensure there's a logged-in user
    const q = query(voicesCollectionRef, where("userId", "==", auth.currentUser.uid));
    const querySnapshot = await getDocs(q);
    const VoicesArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setVoices(VoicesArray);
  };

  // Fetch Voices when the component mounts or auth state changes
  useEffect(() => {
    fetchVoices();
  }, [auth.currentUser]);

  const deleteVoice = async (id) => {
    const voiceDoc = doc(db, "Voices", id); // Get a reference to the voice document
    await deleteDoc(voiceDoc); // Delete the document
    fetchVoices(); // Refresh the list of Voices after deletion
  };
  
  const testVoice = () => {
    return new Promise((resolve, reject) => {
      try {
        const functions = getFunctions();
        const textToSpeech = httpsCallable(functions, 'textToSpeech');
  
        textToSpeech({
          text: "Hello, I am your new AI character",
          voice: { name: voiceSet[1], languageCode: voiceSet[0], ssmlGender: voiceSet[2] },
          audioConfig: { audioEncoding: "MP3", pitch: pitch, speakingRate: speed }
        })
        .then((result) => {
          const audioContent = `data:audio/mp3;base64,${result.data.audioContent}`;
          setAudioSrc(audioContent);
          audioSrcRef.current = audioContent
          resolve(); // Resolve the promise once the audioSrc is set
        })
        .catch((error) => {
          console.error("Error calling function", error.message);
          reject(error); // Reject the promise if there's an error
        });
  
      } catch (error) {
        console.error('Error fetching audio:', error);
        reject(error); // Reject the promise if there's an error
      }
    });
  };
  
  
  return (
    <div className='main-for-page'>

      

<button type="button" className="btn btn-warning create-btn create-voice-jr" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Create+
</button>


<div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Create voice</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>


      <div className="modal-body">
              <form ref={formRef}>



              <div className="mb-3">
              <label htmlFor="model-name" className="col-form-label">Name</label>
      <input 
      className='form-control'
      id="model-name"
        type="text"
        placeholder="Voice Name"
        value={voiceName}
        onChange={(e) => setVoiceName(e.target.value)}
      />
       </div>


       <div className="mb-3">
       <label htmlFor="model-description" className="col-form-label">Description:</label>
      <textarea 
      className='form-control'
        placeholder="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      </div>



      <div className="mb-3">
              <label htmlFor="model-name" className="col-form-label">Voice Model</label>
              <div className="dropdown">
  <button className="btn btn-warning dropdown-toggle " type="button" data-bs-toggle="dropdown" aria-expanded="false">
    {voiceSet[1]}
  </button>
  <ul className="dropdown-menu">
  {voiceOptions.map((option, index) => (
        <li key={index}>
          <button className="dropdown-item" type="button" onClick={() => setVoiceSet(option.value) }>
            {option.label}
          </button>
        </li>
      ))}
  </ul>
</div>
</div>

     
      <label htmlFor="slider-pitch" className="col-form-label">Pitch:</label>
      <div className="slidecontainer">
                  <input
                    type="range"
                    min="-20"
                    max="20"
                    step="0.1"
                    value={pitch}
                    onChange={(e) => setPitch(e.target.value)} // Update the state when the slider value changes
                    className="slider"
                    id="myRange"
                  />
                  <p>Value: {pitch}</p> {/* Display the slider value */}
                </div>




                <label htmlFor="rate-pitch" className="col-form-label">Speed:</label>
      <div className="slidecontainer">
                  <input
                    type="range"
                    min="0.25"
                    max="4"
                    step="0.1"
                    value={speed}
                    onChange={(e) => setSpeed(e.target.value)} // Update the state when the slider value changes
                    className="slider"
                    id="myRange"
                  />
                  <p>Value: {speed}</p> {/* Display the slider value */}
                </div>
           

                <div className='test-audio-btn'>
                <button type="button" className="btn btn-warning " onClick={testVoice}>Test</button>
                <audio controls src={audioSrc}>Your browser does not support the audio element.</audio>
               
    </div>

      </form>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-warning" onClick={handleCreateAndTestVoice} data-bs-dismiss="modal">Create</button>
      </div>
    </div>
  </div>
</div>







      <div className='display-models'>
        {voices.map((voice) => (
          <div key={voice.id}>
        
        <div className="card" style={{width: '18rem'}}>
  <div className="card-body">
    <h5 className="card-title">{voice.name}</h5>
   
    <p className="card-text" >{voice.description}</p>
    <ul className="list-group list-group-flush voice-card" >
    <li className="list-group-item  voice-list-item">{voice.voiceSet[1]} {voice.voiceSet[2]}</li>
    <li className="list-group-item  voice-list-item">Pitch: {voice.pitch}</li>
    <li className="list-group-item  voice-list-item">Speed: {voice.speed}</li>
    <audio controls className="voice-player-preview"src={voice.testVoice}>Your browser does not support the audio element.</audio>
  </ul>
    <button className='btn btn-danger voice-card' onClick={() => deleteVoice(voice.id)}>Delete</button> 
  </div>
</div>
         
          </div>
        ))}
      </div>
    </div>
  );
}

export default ManageVoice;

import React, { useState, useEffect } from 'react';
import { db, storage } from './firebase-config';
import { collection, getDocs, query, where, doc, getDoc, addDoc, deleteDoc } from 'firebase/firestore';
import { auth } from './firebase-config';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import imageCompression from 'browser-image-compression';
import "./styles/Realms.css";

const PRESET_IMAGE_URL = 'https://firebasestorage.googleapis.com/v0/b/ai-backend-415806.appspot.com/o/admin%2Fgreenscreen.jpg?alt=media&token=3624a355-84c9-45ea-88d4-3288ae2533d6';

function Realms() {
  const [RealmName, setRealmName] = useState('');
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [useCustomImage, setUseCustomImage] = useState(true);
  const [error, setError] = useState('');
  const [realms, setRealms] = useState([]);
  const [realmToDelete, setRealmToDelete] = useState(null);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  useEffect(() => {
    const fetchModels = async () => {
      if (!auth.currentUser) return;
      const modelsCollectionRef = collection(db, "models");
      const q = query(modelsCollectionRef, where("userId", "==", auth.currentUser.uid));
      const querySnapshot = await getDocs(q);
      const modelsArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setModels(modelsArray);
    };
    fetchModels();

    const fetchRealms = async () => {
      if (!auth.currentUser) return;
      const realmsCollectionRef = collection(db, "realms");
      const q = query(realmsCollectionRef, where("userId", "==", auth.currentUser.uid));
      const querySnapshot = await getDocs(q);
      const realmsArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setRealms(realmsArray);
    };
    fetchRealms();
  }, [auth.currentUser]);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const options = {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 720,
        useWebWorker: true
      };
      try {
        const compressedFile = await imageCompression(file, options);
        const storageRef = ref(storage, `realms/${auth.currentUser.uid}/${compressedFile.name}`);
        await uploadBytes(storageRef, compressedFile);
        const downloadURL = await getDownloadURL(storageRef);
        setImageUrl(downloadURL);
      } catch (error) {
        setError('Image upload failed');
      }
    }
  };

  const handleAdminImageSelect = () => {
    setImageUrl(PRESET_IMAGE_URL);
  };

  const toggleImageSource = () => {
    setUseCustomImage(!useCustomImage);
    setImageUrl('');
  };

  const saveChanges = async () => {
    if (!RealmName || !imageUrl || !selectedModel) {
      setError('Please fill all the fields');
      return;
    }

    try {
      const realmData = {
        name: RealmName,
        world: imageUrl,
        character: [selectedModel.id],
        userId: auth.currentUser.uid,
        isPresetImage: !useCustomImage
      };

      await addDoc(collection(db, "realms"), realmData);
      setRealmName('');
      setImageUrl('');
      setSelectedModel(null);
      setError('');
      // Close the modal
      const modal = document.getElementById('exampleModal');
      const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
      if (modal) {
        modal.classList.remove('show');
        modal.style.display = 'none';
      }
      if (modalBackdrop) {
        modalBackdrop.parentNode.removeChild(modalBackdrop);
      }
      document.body.classList.remove('modal-open');
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
      // Fetch updated realms
      const fetchRealms = async () => {
        if (!auth.currentUser) return;
        const realmsCollectionRef = collection(db, "realms");
        const q = query(realmsCollectionRef, where("userId", "==", auth.currentUser.uid));
        const querySnapshot = await getDocs(q);
        const realmsArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setRealms(realmsArray);
      };
      fetchRealms();
    } catch (error) {
      setError('Failed to create realm: ' + error.message);
    }
  };

  const deleteRealm = async (id) => {
    try {
      const realmDoc = doc(db, "realms", id);
      const realm = (await getDoc(realmDoc)).data();
      if (realm && !realm.isPresetImage) {
        const imageRef = ref(storage, realm.world);
        await deleteObject(imageRef).catch((error) => {
          console.error(`Failed to delete image: ${error.message}`);
        });
      }
      await deleteDoc(realmDoc);
      setRealms(realms.filter(realm => realm.id !== id));
      setShowConfirmDeleteModal(false);
    } catch (error) {
      setError('Failed to delete realm: ' + error.message);
    }
  };

  const confirmDeleteRealm = (realm) => {
    setRealmToDelete(realm);
    setShowConfirmDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    if (realmToDelete) {
      deleteRealm(realmToDelete.id);
    }
  };

  return (
    <div className='main-for-page-realms'>
      <div className="realms-title">Realms</div>
      <div className="realms-inner-container"> 
        <button type="button" className="btn btn-warning create-btn create-realms-jr" data-bs-toggle="modal" data-bs-target="#exampleModal">
          Create+
        </button>
        <div className="realms-card-display">
          {realms.map((realm) => (
            <div key={realm.id} className="card-main-box-realms">
              <div className="card-main-realms-left">
                <h5>{realm.name}</h5>
                <p>{models.find(model => model.id === realm.character[0])?.name}</p>
                <button className='btn btn-danger' onClick={() => confirmDeleteRealm(realm)}>Delete</button>
              </div>
              <div className="card-main-realms-right">
                <div className="image-container">
                  <img src={realm.world} alt="Realm World" className="img-thumbnail world-image" />
                  {models.find(model => model.id === realm.character[0])?.emotions[models.find(model => model.id === realm.character[0]).mainImage] && (
                    <img src={models.find(model => model.id === realm.character[0]).emotions[models.find(model => model.id === realm.character[0]).mainImage]} alt="Character Emotion" className="img-thumbnail2 character-image" />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="modal fade realms-modal" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog realms-modal">
          <div className="modal-content realms-modal">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">Create Realm</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {error && <div className="alert alert-danger">{error}</div>}
              <label htmlFor="Realm-name" className="col-form-label">Name</label>
              <input 
                className='form-control realms-input-name'
                id="model-name"
                type="text"
                placeholder="Realm Name"
                value={RealmName}
                onChange={(e) => setRealmName(e.target.value)}
              />
              <div className="row mt-3">
                <div className="col-md-8">
                  <div className="form-check form-switch">
                    <input 
                      className="form-check-input" 
                      type="checkbox" 
                      id="imageToggle" 
                      checked={useCustomImage} 
                      onChange={toggleImageSource} 
                    />
                    <label className="form-check-label" htmlFor="imageToggle">
                      {useCustomImage ? 'Use Custom Image' : 'Use Preset Image'}
                    </label>
                  </div>

                  {useCustomImage ? (
                    <>
                      <input
                        type="file"
                        className="form-control"
                        id="upload-image"
                        onChange={handleImageUpload}
                      />
                      {imageUrl && <img src={imageUrl} alt="Uploaded" className="img-thumbnail mt-2 uploaded-image" />}
                    </>
                  ) : (
                    <>
                      <button
                        className="btn btn-primary"
                        onClick={handleAdminImageSelect}
                      >
                        Use Preset Image
                      </button>
                      {imageUrl && (
                        <img src={imageUrl} alt="Preset" className="img-thumbnail mt-2 admin-image" />
                      )}
                    </>
                  )}
                </div>
                <div className="col-md-4">
                  <label htmlFor="model-dropdown" className="col-form-label">Select Model</label>
                  <select
                    id="model-dropdown"
                    className="form-control"
                    onChange={(e) => setSelectedModel(models.find(model => model.id === e.target.value))}
                  >
                    <option value="">Select a model</option>
                    {models.map(model => (
                      <option key={model.id} value={model.id}>{model.name}</option>
                    ))}
                  </select>
                  {selectedModel && selectedModel.mainImage && (
                    <img src={selectedModel.emotions[selectedModel.mainImage]} alt="Model" className="img-thumbnail mt-2 model-image" />
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" onClick={saveChanges}>Save changes</button>
            </div>
          </div>
        </div>
      </div>

      {showConfirmDeleteModal && (
        <div className="modal fade show" tabIndex="-1" style={{ display: "block" }} aria-labelledby="confirmDeleteModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="confirmDeleteModalLabel">Confirm Deletion</h5>
                <button type="button" className="btn-close" onClick={() => setShowConfirmDeleteModal(false)}></button>
              </div>
              <div className="modal-body">
                Are you sure you want to delete the realm "{realmToDelete?.name}"?
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowConfirmDeleteModal(false)}>Cancel</button>
                <button type="button" className="btn btn-danger" onClick={handleConfirmDelete}>Delete</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Realms;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase-config'; 
import { collection, query, where, getDocs, or } from 'firebase/firestore';
import { Tooltip } from 'react-tooltip';
import { db } from './firebase-config'; 
import "./styles/QuickStart.css";
import "./styles/aiCommunicate.css";

const Stream = () => {
    const [characters, setCharacters] = useState([]);
    const charactersCollectionRef = collection(db, "characters");
    const [selectedCharacter, setSelectedCharacter] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [hoveredItemId, setHoveredItemId] = useState(null);
    const navigate = useNavigate();

    const fetchCharacters = async () => {
        if (!auth.currentUser) return; 
        const q = query(
            charactersCollectionRef,
            or(
                where("userId", "==", auth.currentUser.uid),
                where("all", "==", true)
            )
        );
        const querySnapshot = await getDocs(q);
        const charactersArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCharacters(charactersArray);

        // **Added code starts here**
        const greatBobCharacter = charactersArray.find(
            (character) => character.name === "Great Bob" && character.all === true
        );

        if (greatBobCharacter) {
            setSelectedCharacter(greatBobCharacter);
        }
        // **Added code ends here**
    };

    useEffect(() => {
        fetchCharacters();
    }, []);

    const handleStartClickRegular = async () => {
        setLoading(true);
        setTimeout(() => { 
            setLoading(false);
            navigate('/startingup', { state: { selectedCharacter, streaming: false }});
        }, 1500);
    };
    
    const handleStartClick = async () => {
        setLoading(true);
        setTimeout(() => { 
            setLoading(false);
            navigate('/startingup', { state: { selectedCharacter, streaming: true }});
        }, 1500);
    };

    const dropdownStyles = {
        button: {
            backgroundColor: '#ff4740',
            color: '#fff',
            padding: '10px 20px',
            borderRadius: '8px',
            border: 'none',
            cursor: 'pointer',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            transition: 'background-color 0.3s ease',
            fontSize: '16px',
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'middle',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
        },
        dropdownMenu: {
            position: 'absolute',
            top: '100%',
            left: '0',
            width: '100%',
            backgroundColor: '#fff',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            marginTop: '5px',
            zIndex: 1,
            listStyle: 'none',
            padding: '0',
            maxHeight: '200px',
            overflowY: 'auto',
        },
        dropdownItem: (isHovered) => ({
            padding: '10px 15px',
            cursor: 'pointer',
            backgroundColor: isHovered ? '#f7d962' : '#fff',
            color: '#333',
            transition: 'background-color 0.3s ease',
        }),
        dropdownContainer: {
            position: 'relative',
            width: '100%',
            maxWidth: '300px',
            margin: '20px auto',
        },
    };

    return (
        <div className='full-page-quickstart-container'>
            <div className="QuickStart-main-container">
                <div className='start-btn-containers' style={{ display: 'flex', gap: "50px" }}>
                   
                    
                    <button
                        type="button"
                        className={`btn btn-lg start-button-quickstart ${selectedCharacter ? '' : 'hidden-quickstart'}`}
                        disabled={!selectedCharacter}
                        onClick={handleStartClick}
                        style={{ marginTop: '20px', backgroundColor: '#9F56FF' }}   
                    >
                        START
                        <h4>(for streaming) <i  style={{marginLeft: '4px', fontSize: '1.3rem'}}className="fa-regular fa-circle-question" data-tooltip-id="tooltip" 
                        data-tooltip-content="This is how you stream your companions! After you've finished creating your own character and adding twitch redeems, 
                        you can click here to open the control panel and add them to the stream!"></i> 
                        <Tooltip id="tooltip"  style={{ maxWidth: '400px', whiteSpace: 'normal', wordWrap: 'break-word', zIndex: '10',fontSize: '1rem'  }}/>
                        </h4>
                    </button>
                </div>
                <div style={dropdownStyles.dropdownContainer}>
                    <button
                        style={dropdownStyles.button}
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                    >
                        {selectedCharacter ? selectedCharacter.name : "Select Character"}<i className="fa-solid fa-caret-down"></i>
                    </button>
                    {dropdownOpen && (
                        <ul style={dropdownStyles.dropdownMenu}>
                            {characters.map((character) => (
                                <li
                                    key={character.id}
                                    style={dropdownStyles.dropdownItem(hoveredItemId === character.id)}
                                    onMouseEnter={() => setHoveredItemId(character.id)}
                                    onMouseLeave={() => setHoveredItemId(null)}
                                    onClick={() => {
                                        setSelectedCharacter(character);
                                        setDropdownOpen(false);
                                    }}
                                >
                                    {character.name}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className='image-preview-container'>
                    {selectedCharacter && (
                        <img className='image-preview-quickstart' src={selectedCharacter.images.happy.default} alt={selectedCharacter.name} />
                    )}
                </div>
            </div>
            {loading && <div className="TransferScreen-quickstart"></div>}
        </div>
    );
}

export default Stream;

import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';

const CharacterCreatorBuilderVisual = ({
  emotionSelected,
  setEmotionSelected,
  isBlinking,
  setIsBlinking,
  isTalking,
  setIsTalking,
  images,
  setImages,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hoveredItemId, setHoveredItemId] = useState(null);

  const emotionOptions = [
    'Happy',
    'Sadness',
    'Anger',
    'Confusion',
    // Add more emotions as needed
  ];

  const dropdownStyles = {
    button: {
      backgroundColor: '#ff4740',
      color: '#fff',
      padding: '10px 20px',
      borderRadius: '8px',
      border: 'none',
      cursor: 'pointer',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      transition: 'background-color 0.3s ease',
      fontSize: '16px',
      fontWeight: 'bold',
      width: '100%',
      textAlign: 'middle',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
    },
    dropdownMenu: {
      position: 'absolute',
      top: '100%',
      left: '0',
      width: '100%',
      backgroundColor: '#fff',
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      marginTop: '5px',
      zIndex: 1,
      listStyle: 'none',
      padding: '0',
      maxHeight: '200px',
      overflowY: 'auto',
    },
    dropdownItem: (isHovered) => ({
      padding: '10px 15px',
      cursor: 'pointer',
      backgroundColor: isHovered ? '#f7d962' : '#fff',
      color: '#333',
      transition: 'background-color 0.3s ease',
    }),
    dropdownContainer: {
      position: 'relative',
      width: '200px',
      margin: '0 auto',
    },
  };

  const handleSelectEmotion = (emotion) => {
    setEmotionSelected(emotion);
    setDropdownOpen(false);
  };

  const handleImageUpload = (event, type) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/gif')
    ) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages((prevImages) => ({
          ...prevImages,
          [emotionSelected.toLowerCase()]: {
            ...prevImages[emotionSelected.toLowerCase()],
            [type]: reader.result,
          },
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = (type) => {
    setImages((prevImages) => ({
      ...prevImages,
      [emotionSelected.toLowerCase()]: {
        ...prevImages[emotionSelected.toLowerCase()],
        [type]: null,
      },
    }));
  };

  const currentImage = images[emotionSelected.toLowerCase()] || {};

  return (
    <div className='main-visual-preview-area'>
      <div className='visual-preview-area-top'>
        <div></div>
        <div style={dropdownStyles.dropdownContainer}>
          <button
            style={dropdownStyles.button}
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            {emotionSelected} <i className='fa-solid fa-caret-down'></i>
          </button>
          {dropdownOpen && (
            <ul style={dropdownStyles.dropdownMenu}>
              {emotionOptions.map((emotion) => (
                <li
                  key={emotion}
                  style={dropdownStyles.dropdownItem(hoveredItemId === emotion)}
                  onMouseEnter={() => setHoveredItemId(emotion)}
                  onMouseLeave={() => setHoveredItemId(null)}
                  onClick={() => handleSelectEmotion(emotion)}
                >
                  {emotion}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div>
        <i  style={{marginLeft: '4px', fontSize: '1.3rem'}}className="fa-regular fa-circle-question" data-tooltip-id="tooltip" 
                        data-tooltip-content="Gives your character different faces for each emotion they express. Just switch to a given emotion and insert the relevant pics for this to take effect."></i> 
                        <Tooltip id="tooltip"  style={{ maxWidth: '400px', whiteSpace: 'normal', wordWrap: 'break-word', zIndex: '10',fontSize: '1rem'  }}/>
        </div>
      </div>

      <div className='visual-preview-area-middle'>
        <div
          className={`visual-preview-area-middle-extra-container ${
            !isTalking && !isBlinking
              ? 'grid-creator-with-one-item'
              : (isTalking || isBlinking) && !(isTalking && isBlinking)
              ? 'grid-creator-with-two-item'
              : 'grid-creator-with-four-item'
          }`}
        >
          {currentImage.default ? (
            <div className='image-container-creator'>
              <img
                src={currentImage.default}
                alt={emotionSelected}
                className='selected-image'
              />
              <button
                onClick={() => handleRemoveImage('default')}
                className='btn btn-danger remove-image-button'
              >
                Remove Image
              </button>
            </div>
          ) : (
            <div className='middle-visual-preview-create-grid-item'>
              
              {!isTalking && !isBlinking ? 'Add Image' : null}
              {isTalking && isBlinking
                ? 'Eyes Open and Not Talking Image'
                : null}
              {isTalking && !isBlinking ? 'Not Talking Image' : null}
              {!isTalking && isBlinking ? 'Eyes Open Image' : null}
              <div className='add-image-box-and-button'>
                <label htmlFor='file-input-default'>Choose File</label>
                <input
                  id='file-input-default'
                  type='file'
                  accept='image/jpeg, image/png, image/gif'
                  onChange={(e) => handleImageUpload(e, 'default')}
                  className='file-input'
                />
              </div>
            </div>
          )}

          {isBlinking &&
            (currentImage.blinking ? (
              <div className='image-container-creator'>
                <img
                  src={currentImage.blinking}
                  alt={emotionSelected}
                  className='selected-image'
                />
                <button
                  onClick={() => handleRemoveImage('blinking')}
                  className='btn btn-danger remove-image-button'
                >
                  Remove Image
                </button>
              </div>
            ) : (
              <div className='middle-visual-preview-create-grid-item'>
                Blinking {isTalking ? '' : 'Image'}
                {isTalking ? ' and Not Talking Image' : null}
                <div className='add-image-box-and-button'>
                  <label htmlFor='file-input-blinking'>Choose File</label>
                  <input
                    id='file-input-blinking'
                    type='file'
                    accept='image/jpeg, image/png, image/gif'
                    onChange={(e) => handleImageUpload(e, 'blinking')}
                    className='file-input'
                  />
                </div>
              </div>
            ))}

          {isTalking &&
            (currentImage.talking ? (
              <div className='image-container-creator'>
                <img
                  src={currentImage.talking}
                  alt={emotionSelected}
                  className='selected-image'
                />
                <button
                  onClick={() => handleRemoveImage('talking')}
                  className='btn btn-danger remove-image-button'
                >
                  Remove Image
                </button>
              </div>
            ) : (
              <div className='middle-visual-preview-create-grid-item'>
                {isBlinking ? 'Eyes Open and ' : ''}Talking Image
                <div className='add-image-box-and-button'>
                  <label htmlFor='file-input-talking'>Choose File</label>
                  <input
                    id='file-input-talking'
                    type='file'
                    accept='image/jpeg, image/png, image/gif'
                    onChange={(e) => handleImageUpload(e, 'talking')}
                    className='file-input'
                  />
                </div>
              </div>
            ))}

          {isTalking &&
            isBlinking &&
            (currentImage.blinkingTalking ? (
              <div className='image-container-creator'>
                <img
                  src={currentImage.blinkingTalking}
                  alt={emotionSelected}
                  className='selected-image'
                />
                <button
                  onClick={() => handleRemoveImage('blinkingTalking')}
                  className='btn btn-danger remove-image-button'
                >
                  Remove Image
                </button>
              </div>
            ) : (
              <div className='middle-visual-preview-create-grid-item'>
                Blinking and Talking Image
                <div className='add-image-box-and-button'>
                  <label htmlFor='file-input-blinkingTalking'>
                    Choose File
                  </label>
                  <input
                    id='file-input-blinkingTalking'
                    type='file'
                    accept='image/jpeg, image/png, image/gif'
                    onChange={(e) => handleImageUpload(e, 'blinkingTalking')}
                    className='file-input'
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default CharacterCreatorBuilderVisual;

import React, { useState, useEffect } from 'react';
import { auth } from './firebase-config';
import "./styles/Home.css";
import knight from "./styles/Untitleddesign.png"
function Home() {
    const [username, setUsername] = useState('');

    useEffect(() => {
        const user = auth.currentUser;
        if (user) {
            setUsername(user.displayName || 'User');
        }
    }, []);

    return (
        <div className='home-main-container'>
            <div className='home-section-container'>
                <h1 className='home-heading-large home-text-white'>Good Evening, {username}</h1>
                <h1 className='home-heading-medium home-text-white'>New</h1>
                
                <div className='home-carousel-container'>
                    <div className='home-carousel-item'>
                        <div className='home-carousel-item-inner-middle'></div>
                        <div className='home-carousel-item-inner-bottom'>
                            Introducing version 0.75, bringing new voices and twitch integration
                        </div>
                    </div>
                    <div className='home-carousel-item'>
                        <div className='home-carousel-item-inner-middle'></div>
                        <div className='home-carousel-item-inner-bottom'>
                            Learn more about the new features in this update
                        </div>
                    </div>
                    <div className='home-carousel-item'>
                        <div className='home-carousel-item-inner-middle'></div>
                        <div className='home-carousel-item-inner-bottom'>
                            Explore detailed information about the new release
                        </div>
                    </div>
                </div>
                
                <h1 className='home-heading-medium home-text-white'>Trending Realms</h1>
                
                <div className='home-grid-container'>
                    <div className='home-trending-item'>Realm 1</div>
                    <div className='home-trending-item'>Realm 2</div>
                    <div className='home-trending-item'>Realm 3</div>
                    <div className='home-trending-item'>Realm 4</div>
                </div>
                
                <h1 className='home-heading-medium home-text-white'>Create</h1>
                
                <div className='home-create-container'>
                    <div className='home-create-character-container'>
                        <div className='home-create-character-card'>
                            <div className="card">
                                <img src={knight} className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Create Character</h5>
                                    <p className="card-text">Description of the character creation process.</p>
                                    <a href="#" className="btn btn-primary">Create Character</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className='home-voice-realm-container'>
                        <div className='home-full-width'>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Create Voice</h5>
                                    <p className="card-text">Description of the voice creation process.</p>
                                    <a href="#" className="btn btn-primary">Create Voice</a>
                                </div>
                            </div>
                        </div>
                        <div className='home-full-width'>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Create Realm</h5>
                                    <p className="card-text">Description of the realm creation process.</p>
                                    <a href="#" className="btn btn-primary">Create Realm</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h1 className='home-heading-medium home-text-white'>Recent Realms</h1>
                
                <div className='home-grid-container'>
                    <div className='home-realm-item'>Realm A</div>
                    <div className='home-realm-item'>Realm B</div>
                    <div className='home-realm-item'>Realm C</div>
                    <div className='home-realm-item'>Realm D</div>
                    <div className='home-realm-item'>Realm E</div>
                    <div className='home-realm-item'>Realm F</div>
                    <div className='home-realm-item'>Realm G</div>
                    <div className='home-realm-item'>Realm H</div>
                    <div className='home-realm-item'>Realm I</div>
                    <div className='home-realm-item'>Realm J</div>
                    <div className='home-realm-item'>Realm K</div>
                    <div className='home-realm-item'>Realm L</div>
                    <div className='home-realm-item'>Realm M</div>
                    <div className='home-realm-item'>Realm N</div>
                    <div className='home-realm-item'>Realm O</div>
                    <div className='home-realm-item'>Realm P</div>
                    <div className='home-realm-item'>Realm Q</div>
                    <div className='home-realm-item'>Realm R</div>
                </div>
            </div>
        </div>
    );
}

export default Home;

import React, { useState, useEffect, useRef } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import "./styles/textToSpeech.css"

const TextToAudio = ({ message, model, isPlaying, setIsPlaying, voiceSelect, similarityBoost, stability, suprise,gotResponse }) => {
  const [audioSrc, setAudioSrc] = useState('');
  const audioRef = useRef(new Audio("data:audio/mp3;base64,UklGRjIAAABXQVZFZm10IBIAAAABAAEAQB8AAEAfAAABAAgAAABmYWN0BAAAAAAAAABkYXRhAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA=="));

  useEffect(() => {
    if (message && message.length > 0) {
      handleTextSubmit();
    }
  }, [message]);

  const handleTextSubmit = async () => {
    if (voiceSelect) {
      try {
       // console.log("stability: ", stability);
       // console.log("similarity boost: ", similarityBoost);
        let voiceType = "21m00Tcm4TlvDq8ikWAM";
        if (suprise) {
          voiceType = "HPSyCylmursN2qPBRhAR";
        }
        const functions = getFunctions();
        const textToSpeechTurbo = httpsCallable(functions, 'textToSpeechTurbo');
       // console.log("stability ", stability, " similarity_boost ", similarityBoost);
        const result = await textToSpeechTurbo({
          text: message[message.length - 1].content,
          voiceSettings: { stability: stability, similarity_boost: similarityBoost, voiceID: voiceType },
        });

       // console.log('Result:', result);
       // console.log('Audio Content:', result.data.audioContent);

        const audioContent = `data:audio/mp3;base64,${result.data.audioContent}`;
        setAudioSrc(audioContent);
      } catch (error) {
        console.error('Error fetching audio:', error);
      }
    } else {
      try {
        const functions = getFunctions();
        const textToSpeech = httpsCallable(functions, 'textToSpeech');

        const result = await textToSpeech({
          text: message[message.length - 1].content,
          voice: { name: model.voice[1], languageCode: model.voice[0], ssmlGender: model.voice[2] },
          audioConfig: { audioEncoding: "MP3", pitch: model.voicePitch, speakingRate: model.voiceSpeed }
        });

        const audioContent = `data:audio/mp3;base64,${result.data.audioContent}`;
        setAudioSrc(audioContent);
      } catch (error) {
        console.error('Error fetching audio:', error);
      }
    }
  };



  useEffect(() => {
    if (audioSrc) {
      audioRef.current.src = audioSrc;
      audioRef.current.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    }
  }, [audioSrc]);

  return (
    <>
      <audio
        ref={audioRef}
        controls
        autoPlay
        style={{ visibility: 'hidden' }}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onEnded={() => setIsPlaying(false)} // Handle case when audio ends
      >
        Your browser does not support the audio element.
      </audio>
    </>
  );
};

export default TextToAudio;

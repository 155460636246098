import React, { useState } from 'react';
import anger from '../styles/test-images/angy_eyesopen_mouthopen.png';
import confusion from '../styles/test-images/confused_eyesopen_mouthopen.png';
import happiness from '../styles/test-images/happy_eyesopen_mouthopen.png';
import sadness from '../styles/test-images/sad_eyesopen_openmouth.png';
import anger_2 from '../styles/test-images/angry_eyesclosed_mouthclosed.png';
import comfused_2 from '../styles/test-images/confused_eyesopen_mouthclosed.png';
import happy from '../styles/test-images/happy_eyesopen_mouthclosed.png';
import happy2 from '../styles/test-images/happy_eyesclosed_mouthopen.png';

const CharacterCreatorBuilderAttributes = () => {
    const advancedAttributesList = ['Chaos', 'Compassion', 'Emotional Volatility', 'Humility'];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [animationDirection, setAnimationDirection] = useState('');
    const [selectedAttributeImage, setSelectedAttributeImage] = useState(comfused_2);
    const [sliderTest, setSliderTest] = useState(3);

    const handleChangeSlider = (event) => {
        if (Number(event.target.value) === 1) {
            setSelectedAttributeImage(anger);
        } else if (Number(event.target.value) === 2) {
            setSelectedAttributeImage(anger_2);
        } else if (Number(event.target.value) === 3) {
            setSelectedAttributeImage(comfused_2);
        } else if (Number(event.target.value) === 4) {
            setSelectedAttributeImage(happy);
        } else if (Number(event.target.value) === 5) {
            setSelectedAttributeImage(happy2);
        }
        setSliderTest(Number(event.target.value));
    };

    const handleNext = () => {
        setAnimationDirection('right');
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % advancedAttributesList.length);
            setAnimationDirection('');
        }, 300);
    };

    const handlePrevious = () => {
        setAnimationDirection('left');
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + advancedAttributesList.length) % advancedAttributesList.length);
            setAnimationDirection('');
        }, 300);
    };

    return (
        <div className="character-creater-attributes-tab-creator">
            <div className="select-attribute-carousel-container">
                <div className="carrot-select-attributes" onClick={handlePrevious}>
                    <i className="fa-solid fa-caret-left"></i>
                </div>
                <div className="select-attribute-carousel">
                    <div className={`carousel-content ${animationDirection} flex-col`}>
                        {advancedAttributesList[currentIndex]}
                    </div>
                </div>
                <div className="carrot-select-attributes" onClick={handleNext}>
                    <i className="fa-solid fa-caret-right"></i>
                </div>
            </div>

            <div className="character-creator-attributes-image">
                <div className="text-bubble">
                    This is a text bubble!
                </div>
                <img src={selectedAttributeImage} style={{ width: '300px' }} />
            </div>
            
            <input
                type="range"
                min="1"
                max="5"
                value={sliderTest}
                onChange={handleChangeSlider}
                className="creator-slider"
            />
            <div className="slider-labels-container">
                <div className="slider-label">
                    <div className="slider-marker"></div>
                    <span>Extremely Not</span>
                </div>
                <div className="slider-label">
                    <div className="slider-marker"></div>
                    <span>Rather Not</span>
                </div>
                <div className="slider-label">
                    <div className="slider-marker"></div>
                    <span>Somewhat</span>
                </div>
                <div className="slider-label">
                    <div className="slider-marker"></div>
                    <span>Rather Yes</span>
                </div>
                <div className="slider-label">
                    <div className="slider-marker"></div>
                    <span>Absolutely Yes</span>
                </div>
            </div>
        </div>
    );
}

export default CharacterCreatorBuilderAttributes;

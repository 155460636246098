// SideNav.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './styles/Logo-yellow.png';
import { auth } from './firebase-config';
import './styles/sideContainer.css';

function SideNav({ changeComponent, hideSideNav, onSignOut }) {
  const [isClosed, setIsClosed] = useState(false);
  const [usage, setUsage] = useState(0);
  const navigate = useNavigate();

  const signOut = () => {
    auth.signOut();
    handleNavigation('signin');
  };

  const toggleNav = () => {
    setIsClosed(!isClosed);
  };

  const handleNavigation = (component) => {
    if (changeComponent) {
      changeComponent(component);
    } else {
      navigate(`/${component.toLowerCase()}`);
    }
  };

  // Simulate usage increase
  useEffect(() => {
    const interval = setInterval(() => {
      setUsage((prevUsage) => (prevUsage < 100 ? prevUsage + 1 : 100));
    }, 100);
    return () => clearInterval(interval);
  }, []);

  const buttonTransform = isClosed ? 'translateX(-287px)' : 'translateX(0)';

  if (hideSideNav) {
    return null;
  }

  // Circle rendering logic
  const circleRadius = 50;
  const circleCircumference = 2 * Math.PI * circleRadius;
  const progressOffset = ((100 - usage) / 100) * circleCircumference;

  return (
    <div>
      <button
        className="closebtn"
        onClick={toggleNav}
        style={{ transform: buttonTransform }}
      >
        <i className="fa-solid fa-bars" style={{ transform: 'rotate(90deg)' }}></i>
      </button>
      <div className={`container-side ${isClosed ? 'closed' : ''}`}>
        <div className="btn-container">
          {!isClosed && (
            <>
              <h1 className="title-side">Chaotic Companions</h1>
              <img
                className="logo-side-nav"
                src={logo}
                alt="Logo"
                style={{
                  width: '50px',
                  height: 'auto',
                  marginTop: -35,
                  marginBottom: 15,
                  marginLeft: 0,
                }}
              />
              <div className="line"></div>
              <button
                onClick={signOut}
                className="btn btn-signout sign-out"
                style={{ marginBottom: '5px' }}
              >
                {auth.currentUser?.isAnonymous ? 'Sign In' : 'Sign Out'}
              </button>
              <div style={{ gap: '10px' }}>
                <button
                  className="side-button interact-jr"
                  onClick={() => handleNavigation('QuickStart')}
                >
                  <i className="fa-solid fa-play side-nav-icon"></i> Quick Start
                </button>
                <button
                  className="side-button twitch-chat-jr"
                  onClick={() => handleNavigation('Twitch')}
                >
                  <i className="fa-solid fa-comments side-nav-icon"></i> Twitch Redeems
                </button>
                <button
                  className="side-button interact-jr"
                  style={{ marginLeft: '10px' }}
                  onClick={() => handleNavigation('Training')}
                >
                  <i
                    className="fa-solid fa-person-rays"
                    style={{ marginRight: '23px' }}
                  ></i>{' '}
                  Characters
                </button>
                <button
                  className="side-button interact-jr"
                  style={{ marginLeft: '10px' }}
                  onClick={() => handleNavigation('Create')}
                >
                  <i className="fa-solid fa-user-pen"></i> Create
                </button>
              </div>
            </>
          )}
        </div>
        {/* Uncomment the usage info section if needed */}
        {/* <div className={`usage-info-container ${isClosed ? 'closed' : ''}`}>
          <div className={`usage-circle-container ${isClosed ? 'closed' : ''}`}>
            <svg className="usage-circle" height={isClosed ? 0 : 120} width={isClosed ? 0 : 120}>
              <circle
                className="circle-background"
                cx="60"
                cy="60"
                r={circleRadius}
                strokeWidth="10"
              />
              <circle
                className="circle-progress"
                cx="60"
                cy="60"
                r={circleRadius}
                strokeWidth="10"
                strokeDasharray={circleCircumference}
                strokeDashoffset={progressOffset}
              />
            </svg>
            <div className="usage-percentage" style={{ opacity: isClosed ? 0 : 1 }}>
              {usage}%
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default SideNav;

import React, { useState, useEffect, useRef } from 'react';
import "./styles/aiCommunicate.css";
import "./styles/switch.css";
import Visual from "./visual";
import ManageModels from './ManageModels';
import AiConversationManager from './AiConversationManager';
import { db } from './firebase-config'; 
import { auth } from './firebase-config'; 
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import SpeechToText from './SpeechToText';
import TextToAudio from './Text-to-speech';
import StartUpFunctions from './start-up-functions';
import TestAudioComponent from './testAudioComponent';

function Communicate({selectedModel, selectedRealm }) {
  const [conversation, setConversation] = useState([]);
 
  const [models, setModels] = useState([]);
  const [realms, setRealms] = useState([]);
  const [speechToTextMessage, setSpeechToTextMessage] = useState('');
  const [gotResponse, setGotResponse] = useState(false);
  const [type, setType] = useState("vertex");
  const modelsCollectionRef = collection(db, "models");
  const realmsCollectionRef = collection(db, "realms");
  const [audioInitialized, setAudioInitialized] = useState(false);
  const [isAudioMuted, setIsAudioMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [runTutorial, setRunTutorial] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [voiceClicked, setVoiceClicked] = useState(false);
  const [isRadioSelected, setIsRadioSelected] = useState(false);
  const [supriseMode, setSupriseMode] = useState(false); // State variable for radio button
  const [stability, setStability] = useState(0.5); // State variable for stability
  const [similarityBoost, setSimilarityBoost] = useState(0.5); // State variable for similarity boost
  const [emotion, setEmotion] = useState("Neutral");
  const [isTutorialButtonVisible, setIsTutorialButtonVisible] = useState(true); // State for button visibility
const [initialLoad, setInitialLoad] = useState(true); // State for initial load
  const selectRealmButtonRef = useRef(null); // Ref for the select realm button


  





  return (
    <div className="ai-container">

      <div className="dropdown-container">
        <div className="dropdown purple">
         
         
        </div>
  
    
      
      </div>
   
      <Visual
        className="visual-area"
        conversation={conversation}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        emotion={emotion}
        selectedModel={selectedModel}
        background={selectedRealm ? selectedRealm.world : null}
      />
      <SpeechToText
        setSpeechToTextMessage={setSpeechToTextMessage}
        gotResponse={gotResponse}
        setGotResponse={setGotResponse}
        setIsAudioMuted={setIsAudioMuted}
        isPlaying={isPlaying}
        initialLoad={initialLoad}
        setInitialLoad={setInitialLoad}
      />
      <AiConversationManager
        selectedModel={selectedModel}
        speechToTextMessage={speechToTextMessage}
        gotResponse={gotResponse}
        setGotResponse={setGotResponse}
        conversation={conversation}
        setConversation={setConversation}
        type={type}
        isPlaying={isPlaying}
        setEmotion={setEmotion}
      />
      <TextToAudio
        similarityBoost={similarityBoost}
        stability={stability}
        message={conversation}
        model={selectedModel}
        setIsAudioMuted={setIsAudioMuted}
        setIsPlaying={setIsPlaying}
        voiceSelect={isRadioSelected}
        suprise={supriseMode}
        isPlaying={isPlaying}
        gotResponse={gotResponse}
      />
      
    </div>
  );
}

export default Communicate;

import { getDefaultNormalizer } from "@testing-library/react"
import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
const functions = getFunctions();
const textToSpeech = httpsCallable(functions, 'textToSpeech');
const textToSpeechTurbo = httpsCallable(functions,'textToSpeechTurbo')
const emotions = httpsCallable(functions,"queryHuggingFace" )
function StartUpFunctions(){

    useEffect(() =>{
       
       async function call(){
        //console.log("starting")
        const result = await textToSpeech({
          text:"hello",
          voice: { name: "en-US-Casual-k", languageCode: "en-us", ssmlGender: "MALE" },
          audioConfig: { audioEncoding: "MP3", pitch: 10, speakingRate: 1 }
        });
        const audioContent = `data:audio/mp3;base64,${result.data.audioContent}`;
        
       

        const predictFromAI = httpsCallable(functions, 'predictFromAI');
    const prompt = {
        context: `hi`,
        messages: [{role: "user", content: "1"}],
        tokens: 1
    };
     predictFromAI({prompt})
     .then((result) =>{
        
        const {predictions} = result.data
       // console.log("functions ready " + predictions)
    
     })
   

const emote = await emotions({
  inputs: "hi",
  parameters: {}
});
    //console.log(emote.data[0].label)
    }
       call()
})
   
    return(<div></div>)
}

export default StartUpFunctions
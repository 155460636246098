import React, { createContext, useState, useContext, useCallback } from 'react';
import { createRoot } from 'react-dom/client';

const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
    const [popup, setPopup] = useState(null);
    const [popupRoot, setPopupRoot] = useState(null);

    const openPopup = useCallback(() => {
        const newPopup = window.open(
            '', 
            'popupWindow', 
            'width=600,height=600,scrollbars=no,toolbar=no,location=no,status=no,menubar=no,resizable=yes'
        );

        if (newPopup) {
            newPopup.document.write(`
                <html>
                <head>
                    <title>Stream Communicate</title>
                         <style>
                        body {
                            margin: 0;
                            overflow: hidden;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100vh;
                            background-color: rgba(0, 0, 0, 0.5);
                        }
                    </style>
                </head>
                <body>
                    <div id="popup-root"></div>
                </body>
                </html>
            `);
            newPopup.document.close();

            const root = createRoot(newPopup.document.getElementById('popup-root'));
            setPopup(newPopup);
            setPopupRoot(root);
        }
    }, []);

    const closePopup = useCallback(() => {
        if (popup) {
            popup.close();
            setPopup(null);
            setPopupRoot(null);
        }
    }, [popup]);

    return (
        <PopupContext.Provider value={{ openPopup, closePopup, popup, popupRoot }}>
            {children}
        </PopupContext.Provider>
    );
};

export const usePopup = () => useContext(PopupContext);

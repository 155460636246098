// App.js
import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { auth } from './components/firebase-config';
import ProtectedRoute from './components/ProtectedRoute';

import SignIn from './components/signIn';
import PrivacyPolicy from './components/legal/PrivacyPolicy';
import EmailVerification from './components/verificationpages/EmailVerification';
import EmailVerficationSend from './components/verificationpages/EmailVerficationSend';
import LandingPage from './components/LandingPage';
import MainApp from './components/MainApp';

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [authIsReady, setAuthIsReady] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Auth state listener
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      setAuthIsReady(true);
    });
    return () => unsubscribe();
  }, []);

  // Wait until auth is ready
  if (!authIsReady) {
    return <div>Loading...</div>; // Or a spinner/loading component
  }

  // Handle sign-out
  const handleSignOut = () => {
    auth.signOut().then(() => {
      setCurrentUser(null);
      navigate('/signin', { replace: true });
    });
  };

  return (
    <div className="App">
      <Routes>
        {/* Public Routes */}
        <Route path="/signin" element={<SignIn />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/verify-email" element={<EmailVerficationSend />} />
        <Route path="/send-email-verification" element={<EmailVerficationSend />} />
        <Route path="/landingpage" element={<LandingPage />} />

        {/* Protected Routes */}
        <Route
          path="/*"
          element={
            <ProtectedRoute currentUser={currentUser}>
              <MainApp
                currentUser={currentUser}
                handleSignOut={handleSignOut}
              />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;

// MainApp.js
import React from 'react';
import { Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import SideNav from './sideNav';
import Stream from './Stream';
import QuickStart from './QuickStart';
import Training from './Training';
import TwitchTab from './TwitchTab';
import Realms from './Realms';
import Home from './Home';
import Voice from './Voice';
import Settings from './Settings';
import CharacterCreator from './CharacterCreator';
import StartingUpWrapper from './StartingUpWrapper';
import CharacterCreatorBuilder from './CharacterCreatorBuilder';
import PrivacyPolicy from './legal/PrivacyPolicy';
import { auth } from './firebase-config';
import '../App.css'; // Import your global styles if needed

function MainApp({ currentUser }) {
  const location = useLocation();
  const navigate = useNavigate();

  const handleSignOut = () => {
    auth.signOut().then(() => {
      navigate('/signin', { replace: true });
    });
  };

  const handleChangeComponent = (componentName) => {
    navigate(`/${componentName.toLowerCase()}`, { replace: true });
  };

  // Determine if SideNav should be hidden
  const hideSideNav =
    location.pathname === '/startingup' || location.pathname.startsWith('/character-builder');

  return (
    <div className="main-window">
      {/* Conditionally render SideNav */}
      {!hideSideNav && (
        <SideNav
          changeComponent={handleChangeComponent}
          onSignOut={handleSignOut}
          hideSideNav={hideSideNav}
        />
      )}
      <Routes>
        <Route path="/dashboard" element={<Stream />} />
        <Route path="/quickstart" element={<Stream />} />
        <Route path="/training" element={<Training />} />
        <Route path="/twitch" element={<TwitchTab />} />
        <Route path="/realms" element={<Realms />} />
        <Route path="/home" element={<Home />} />
        <Route path="/voice" element={<Voice />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/startingup" element={<StartingUpWrapper />} />
        <Route
          path="/create"
          element={
            <CharacterCreator
              hideSideNav={hideSideNav}
              setHideSideNav={() => {}} // Replace with your actual function
            />
          }
        />
        <Route
          path="/character-builder/:type"
          element={
            !currentUser?.isAnonymous ? (
              <CharacterCreatorBuilder />
            ) : (
              <CharacterCreator
                hideSideNav={hideSideNav}
                setHideSideNav={() => {}}
              />
            )
          }
        />
        {/* Default route */}
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        {/* Catch-all route */}
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </div>
  );
}

export default MainApp;

import React, { useState, useRef, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

const StreamSpeechToText = ({ isListening, setIsListening, setOnTranscription }) => {
    const [transcription, setTranscription] = useState('');
    const [decibels, setDecibels] = useState(0);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const analyserRef = useRef(null);
    const audioContextRef = useRef(null);
    const sourceRef = useRef(null);
    const animationFrameIdRef = useRef(null);
    const silenceTimeoutRef = useRef(null);
    const noSoundTimeoutRef = useRef(null); // Ref to handle no sound timeout
    const hasExceededThresholdRef = useRef(false);
    const recordingRef = useRef(true); // Set initial state to true to start recording
    const silenceDuration = 500;
    const decibelCutoff = 25;
    const noSoundDuration = 20000; // 20 seconds
    let noSoundDuractionMet = false

    const messages = [
        "Say hello",
        "Greet me",
        "Ask how I am doing",
        "Say hi to the audience"
    ];
    const messageIndexRef = useRef(0);

    const getNextMessage = () => {
        const message = messages[messageIndexRef.current];
        messageIndexRef.current = (messageIndexRef.current + 1) % messages.length;
        return message;
    };

    const startRecording = async () => {
        //console.log("Recording")
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        analyserRef.current = audioContextRef.current.createAnalyser();
        analyserRef.current.fftSize = 256;
        const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);

        sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
        sourceRef.current.connect(analyserRef.current);

        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = event => {
            audioChunksRef.current.push(event.data);
        };
        mediaRecorderRef.current.onstop = handleStop;
        mediaRecorderRef.current.start();

        const updateDecibels = () => {
            analyserRef.current.getByteFrequencyData(dataArray);
            const sum = dataArray.reduce((a, b) => a + b, 0);
            const average = sum / dataArray.length;
            setDecibels(average);

            if (average > decibelCutoff) {
                hasExceededThresholdRef.current = true;
                if (noSoundTimeoutRef.current) {
                    clearTimeout(noSoundTimeoutRef.current);
                    noSoundTimeoutRef.current = null;
                }
            }

            if (average < 10 && hasExceededThresholdRef.current) {
                if (!silenceTimeoutRef.current) {
                    silenceTimeoutRef.current = setTimeout(() => {
                        stopRecording();
                        hasExceededThresholdRef.current = false;
                        silenceTimeoutRef.current = null;
                    }, silenceDuration);
                }
            } else {
                if (silenceTimeoutRef.current) {
                    clearTimeout(silenceTimeoutRef.current);
                    silenceTimeoutRef.current = null;
                }
            }

            animationFrameIdRef.current = requestAnimationFrame(updateDecibels);
        };

        updateDecibels();

        noSoundTimeoutRef.current = setTimeout(() => {
            if (!hasExceededThresholdRef.current) {
                noSoundDuractionMet = true
                stopRecording();
                const nextMessage = getNextMessage();
                setTranscription(nextMessage);
                setOnTranscription(nextMessage);
                setIsListening(false);
            }
        }, noSoundDuration);
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
            mediaRecorderRef.current.stop();
        }
        if (animationFrameIdRef.current) {
            cancelAnimationFrame(animationFrameIdRef.current);
        }
        if (noSoundTimeoutRef.current) {
            clearTimeout(noSoundTimeoutRef.current);
            noSoundTimeoutRef.current = null;
        }
    };

    const handleStop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
        audioChunksRef.current = [];
        const base64Audio = await convertBlobToBase64(audioBlob);

        if (recordingRef.current && !noSoundDuractionMet) {
            sendToSpeechToText(base64Audio);
        } else {
            noSoundDuractionMet = false;
        }
    };

    const convertBlobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => resolve(reader.result.split(',')[1]);
            reader.onerror = reject;
        });
    };

    const sendToSpeechToText = async (base64Audio) => {
        const functions = getFunctions();

        const speechToText = httpsCallable(functions, 'speechToText');
        try {
            //console.log("Sending recorded speech")
          //  console.log("SENTT")
            const result = await speechToText({ audioBuffer: base64Audio });
            setTranscription(result.data.text);
            setOnTranscription(result.data.text);
            setIsListening(false);
           // console.log("Received transcription: ", result.data.text);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        if (isListening) {
            startRecording();
        } else {
            stopRecording();
        }
    }, [isListening]);

    return (
        <></>
    );
};

export default StreamSpeechToText;

import React, { useState, useEffect } from 'react';
import TwitchLogin from './TwitchLogin';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import './styles/TwitchTab.css';
const defaultRewards = [
  { title: "Make Great Bob Sad", prompt: "Please Be Sad", cost: 50,is_user_input_required: false, },
  { title: "Make Great Bob Angry", prompt: "Please Be Angry", cost: 50,is_user_input_required: false, },
  { title: "Make Great Bob Confused", prompt: "Please Be Confused", cost: 50,is_user_input_required: false, },
  { title: "Make Great Bob Happy", prompt: "Please Be Happy", cost: 50,is_user_input_required: false, },
  { title: "Who Is Great Bob's Favorite Person", prompt: "Who is your favorite person?", cost: 50, is_user_input_required: false, },
  { title: "Write a poem about [your input here]", prompt: "Write a Poem about..", cost: 100 , is_user_input_required: true },
  { title: "Write a Poem about me", prompt: "Write a Poem about me that rhymes with my name", cost: 100 , is_user_input_required: false },
  { title: "Notice Me!", prompt: "Say a nice greeting to me", cost: 5 , is_user_input_required: false },
  { title: "Praise Me!", prompt: "Exhault Praise Upon Me", cost: 100 , is_user_input_required: false },
  { title: "Ask Great Bob a question", prompt: "Ask great bob a question", cost: 100 , is_user_input_required: true },
];

const TwitchTab = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [userId, setUserId] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const [redemptions, setRedemptions] = useState([]);
  const [token, setToken] = useState('');
  const [rewardIds, setRewardIds] = useState([]); // Use an array for reward IDs
  const [customRewards, setCustomRewards] = useState([]); // Use an array for custom rewards
  const [customRewardTitle, setCustomRewardTitle] = useState('');
  const [customRewardPrompt, setCustomRewardPrompt] = useState('');
  const [customRewardCost, setCustomRewardCost] = useState('');
  const [isUserInputRequired, setIsUserInputRequired] = useState(false);
  const [displayedMessage, setDisplayedMessage] = useState('');
  
  const location = useLocation();

  useEffect(() => {
  
    const hash = location.hash;
    const accessToken = new URLSearchParams(hash.substring(1)).get('access_token');

    if (accessToken) {
      localStorage.setItem('twitch_token', accessToken);
      setToken(accessToken);
      fetchUserDetails(accessToken);
      window.history.replaceState(null, null, ' '); // Clear the URL fragment
    } else {
      const storedToken = localStorage.getItem('twitch_token');
      if (storedToken) {
        setToken(storedToken);
        fetchUserDetails(storedToken);
      }
    }
  }, [location.hash]);

  useEffect(() => {
    if (isLoggedIn) {
      validateToken();
    }
  }, [isLoggedIn, token]);

  const fetchUserDetails = async (accessToken) => {
    try {
      const response = await fetch(`https://api.twitch.tv/helix/users`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Client-ID': 'c0i4yt83s4fmr4d76renpisjqnd8cy'
        }
      });
      const data = await response.json();
      if (data.data.length > 0) {
        const user = data.data[0];
        setUsername(user.display_name);
        setUserId(user.id);
        setIsLoggedIn(true);
        checkStreamingStatus(accessToken, user.id);
        fetchCustomRewards(accessToken, user.id);
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const checkStreamingStatus = async (accessToken, userId) => {
    try {
      const response = await fetch(`https://api.twitch.tv/helix/streams?user_id=${userId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Client-ID': 'c0i4yt83s4fmr4d76renpisjqnd8cy'
        }
      });
      const data = await response.json();
      setIsStreaming(data.data.length > 0);
    } catch (error) {
      console.error('Error checking streaming status:', error);
    }
  };

  const validateToken = async () => {
    try {
      const response = await fetch(`https://id.twitch.tv/oauth2/validate`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (data.scopes.includes("channel:manage:redemptions")) {
        return true;
      } else {
        console.error('Invalid scopes.');
        return false;
      }
    } catch (error) {
      console.error('Error validating token:', error);
      return false;
    }
  };

  const fetchCustomRewards = async (accessToken, userId) => {
    try {
      const response = await fetch(`https://api.twitch.tv/helix/channel_points/custom_rewards?broadcaster_id=${userId}&only_manageable_rewards=true`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Client-ID': 'c0i4yt83s4fmr4d76renpisjqnd8cy'
        }
      });
      const data = await response.json();
      if (data.data && data.data.length > 0) {
        setRewardIds(data.data.map(reward => reward.id));
        setCustomRewards(data.data); // Store the custom rewards
      }
    } catch (error) {
      console.error('Error fetching custom rewards:', error);
    }
  };

  const createCustomReward = async (accessToken, userId, reward) => {
    const customRewardBody = {
      title: reward.title.slice(0, 45),
      prompt: reward.prompt.slice(0, 200),
      cost: parseInt(reward.cost, 10) || 10,
      is_enabled: true,
      is_user_input_required: isUserInputRequired,
    };

    try {
      const response = await fetch(`https://api.twitch.tv/helix/channel_points/custom_rewards?broadcaster_id=${userId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Client-ID': 'c0i4yt83s4fmr4d76renpisjqnd8cy',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(customRewardBody)
      });
      const data = await response.json();
      //console.log('Custom reward created:', data)
      if (data.data && data.data.length > 0) {
        setRewardIds(prev => [...prev, data.data[0].id]); // Add new reward ID to rewardIds
        setCustomRewards(prev => [...prev, data.data[0]]); // Add new reward to customRewards
      }
    } catch (error) {
      console.error('Error creating custom reward:', error);
    }
  };

  const deleteCustomReward = async (accessToken, rewardId) => {
    try {
      await fetch(`https://api.twitch.tv/helix/channel_points/custom_rewards?broadcaster_id=${userId}&id=${rewardId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Client-ID': 'c0i4yt83s4fmr4d76renpisjqnd8cy',
          'Content-Type': 'application/json'
        }
      });
      setCustomRewards(prev => prev.filter(reward => reward.id !== rewardId));
      setRewardIds(prev => prev.filter(id => id !== rewardId));
    } catch (error) {
      console.error('Error deleting custom reward:', error);
    }
  };

  const handleCreateReward = (e) => {
    e.preventDefault();
    const accessToken = localStorage.getItem('twitch_token');
    createCustomReward(accessToken, userId, {
      title: customRewardTitle,
      prompt: customRewardPrompt,
      cost: customRewardCost,
    });
  };

  const handleDeleteReward = (rewardId) => {
    const accessToken = localStorage.getItem('twitch_token');
    deleteCustomReward(accessToken, rewardId);
  };

  const handleAddDefaultReward = (reward) => {
    const accessToken = localStorage.getItem('twitch_token');
    createCustomReward(accessToken, userId, reward);
  };

  const handleAddAllDefaultRewards = () => {
    const accessToken = localStorage.getItem('twitch_token');
    defaultRewards.forEach(reward => createCustomRewardDefault(accessToken, userId, reward));
  };

  const createCustomRewardDefault = async (accessToken, userId, reward) => {
    const customRewardBody = {
      title: reward.title.slice(0, 45),
      prompt: reward.prompt.slice(0, 200),
      cost: parseInt(reward.cost, 10) || 10,
      is_enabled: true,
      is_user_input_required: reward.is_user_input_required,
    };

    try {
      const response = await fetch(`https://api.twitch.tv/helix/channel_points/custom_rewards?broadcaster_id=${userId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Client-ID': 'c0i4yt83s4fmr4d76renpisjqnd8cy',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(customRewardBody)
      });
      const data = await response.json();
      //console.log('Custom reward created:', data)
      if (data.data && data.data.length > 0) {
        setRewardIds(prev => [...prev, data.data[0].id]); // Add new reward ID to rewardIds
        setCustomRewards(prev => [...prev, data.data[0]]); // Add new reward to customRewards
      }
    } catch (error) {
      console.error('Error creating custom reward:', error);
    }
  };
  

  const handleLogout = () => {
    localStorage.removeItem('twitch_token');
    setToken('');
    setUsername('');
    setUserId('');
    setIsLoggedIn(false);
    setIsStreaming(false);
    setRedemptions([]);
    setRewardIds([]);
    setCustomRewards([]);
    setCustomRewardTitle('');
    setCustomRewardPrompt('');
    setCustomRewardCost('');
    setIsUserInputRequired(false);
    setDisplayedMessage('');
  };

  return (
    <div className='main-twitch-tab-component'>
      <div className='twitch-tab-items-cotnainer'>
      <h1 className="twitch-tab-title"style={{ color: 'white' }}>Twitch Redeems <i  style={{marginLeft: '4px', fontSize: '1.3rem'}}className="fa-regular fa-circle-question" data-tooltip-id="tooltip" 
                        data-tooltip-content={'Here are the Custom Redeems we\'ve added to make your character go nuts. Feel free to create your own, edit, delete and view which ones you\'ve added at the bottom. Clicking this button requires your chatter to enter in a bit of text like "write a poem about" BLANK'}></i> 
                        <Tooltip id="tooltip"  style={{ maxWidth: '400px', whiteSpace: 'normal', wordWrap: 'break-word', zIndex: '10',fontSize: '1rem'  }}/></h1> 
      {isLoggedIn ? (
        <div>
          <div className='regular-container-twitch'> 
          <h2 style={{ color: 'white', marginRight: "-40px"}}>Logged in as {username}</h2>
          <button className="btn btn-danger"onClick={handleLogout}>Logout</button>
          </div>
         
          
          <div className='Twitch-tab-create-reward'>
            <h3 style={{ color: 'white' }}>Create Custom Channel Point Reward</h3>
            <form onSubmit={handleCreateReward}>
              <input
                type="text"
                placeholder="Reward Title (max 45 chars)"
                value={customRewardTitle}
                onChange={(e) => setCustomRewardTitle(e.target.value.slice(0, 45))}
                required
              />
              <input
                type="text"
                placeholder="Reward Prompt (max 200 chars)"
                value={customRewardPrompt}
                onChange={(e) => setCustomRewardPrompt(e.target.value.slice(0, 200))}
              />
              <input
              className='input-twitch-cost'
                type="number"
                placeholder="Reward Cost"
                value={customRewardCost}
                onChange={(e) => setCustomRewardCost(e.target.value)}
                required
              />
              <div style={{ color: 'white' }}>
              <input

              
            type="checkbox"
            class="twitch-input-required-checkbox"
            checked={isUserInputRequired}
            onChange={(e) => setIsUserInputRequired(e.target.checked)}
            />
                <label style={{marginLeft: "5px"}}>Chat Input Required</label>
              </div>
              <button className='btn btn-success' type="submit">Create Reward</button>
            </form>
          </div>
          <div className='preset-reward-twitch-container'>
            <h3 style={{ color: 'white' }}>Preset Rewards</h3>
            <div className="preset-reward-twitch" >
            {defaultRewards.map((reward, index) => (
              <div key={index}  className="preset-reward-item" style={{ border: "2px black solid", color: "black", backgroundColor: "white", margin: "5px", padding: "10px" }}>
                <strong>Title:</strong> {reward.title} <br />
                <strong>Prompt:</strong> {reward.prompt} <br />
                <strong>Cost:</strong> {reward.cost} <br />
                <strong>User Input Required:</strong> {reward.is_user_input_required ? 'Yes' : 'No'}<br />
               {/* <button className='btn btn-success '  onClick={() => handleAddDefaultReward(reward)}>Add Reward</button> */}
              </div>
            ))}
            </div>
            <button className='btn btn-success ' onClick={handleAddAllDefaultRewards}>Add All Default Rewards</button>
            
          </div>
          <div className='custom-rewards-container'>
            <h3 style={{ color: 'white' }}>Enabled Redeems</h3>
            <ul style={{ color: 'white' }}>
              {customRewards && customRewards.map((reward, index) => (
                <li key={index} style={{ border: "4px black solid", color: "black", backgroundColor: "white", margin: "5px", padding: '5px', borderRadius: '5px' }}>
                  <strong>Title:</strong> {reward.title}<br />
                  <strong>Prompt:</strong> {reward.prompt}<br />
                  <strong>Cost:</strong> {reward.cost}<br />
                  <strong>User Input Required:</strong> {reward.is_user_input_required ? 'Yes' : 'No'}<br />
                  <button className="btn btn-danger" onClick={() => handleDeleteReward(reward.id)}>Delete</button>
                </li>
              ))}
            </ul>
          </div>
      
        </div>
      ) : (
        <TwitchLogin />
      )}
      </div>
    </div>
  );
};

export default TwitchTab;

import React from 'react';

const StreamPopUp = ({ selectedModel, selectedRealm, Image, backgroundColor, imageSize }) => {
    const popupMainStyle = {
        width: '100vw',
        height: '100vh',
        backgroundColor: backgroundColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    };

    const popupImageStyle = {
        width: `${imageSize}%`,
        backgroundColor: backgroundColor
    };

    return (
        <div style={popupMainStyle}>
            <img style={popupImageStyle} src={Image} alt='Fun AI Companion' />
        </div>
    );
}

export default StreamPopUp;
